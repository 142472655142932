<template>
  <BaseContent>
    <div class="flex flex-wrap overflow-hidden rounded-lg lg:flex-nowrap">
      <CmsImage
        v-if="props.content?.image?.url"
        :src="props.content?.image?.url"
        :width="props.content?.image?.width"
        :height="props.content?.image?.height"
        class="aspect-square w-full bg-cover bg-center object-cover object-center lg:w-1/3"
        :max-width="960"
      />
      <div
        class="flex w-full flex-col gap-8 p-8 lg:w-2/3 lg:p-16 lg:pb-20"
        :style="backgroundColor"
      >
        <div class="flex flex-col gap-2.5">
          <h3 class="typo-h2">{{ name }}</h3>
          <span class="typo-body-xl text-grey-500">{{ jobTitle }}</span>
        </div>
        <div class="typo-body-2xl" v-html="quote" />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const name = computed(() => props.content?.name);
const jobTitle = computed(() => props.content?.job_title);
const quote = computed(() => props.content?.quote);
const backgroundColor = computed(() =>
  props.content?.background_color
    ? `background-color: ${props.content?.background_color}`
    : "",
);
</script>
