<template>
  <BaseContent>
    <div>
      <h2 class="typo-h5 mb-8 font-bold text-grey-700">
        {{ $t("service_page.why_officeguru_is_right_for_you") }}
      </h2>
      <div class="mb-8 grid gap-8 lg:grid-cols-3">
        <div
          v-for="card in cards"
          :key="card.id"
          class="card flex gap-4 rounded-lg bg-grey-50 p-4"
        >
          <BaseIcon :icon="card.icon" :size="30" :class="card.colorClass" />
          <div>
            <p class="typo-body font-semibold text-grey-900">
              {{ card.title }}
            </p>
            <p class="typo-body text-grey-700">
              {{ card.text }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <BaseButton :href="cmsValues?.link_customer_signup">{{
          $t("service_page.get_started")
        }}</BaseButton>
        <BaseButton
          :href="cmsValues?.link_how_it_works"
          type="outline"
          color="black"
          >{{ $t("service_page.learn_more") }}</BaseButton
        >
      </div>
    </div>
  </BaseContent>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useCMSStore } from "~/store/CMSStore";

const { cmsValues } = useCMSStore();
const { t } = useI18n();
const cards: Array<{
  id: number;
  icon: BaseIconName;
  title: string;
  text: string;
  colorClass: string;
}> = [
  {
    id: 1,
    icon: "pc-support",
    title: t("service_page.always_available"),
    text: t(
      "service_page.availability_is_a_prerequisite_for_a_good_partnership",
    ),
    colorClass: "text-semantic-info-700",
  },
  {
    id: 2,
    icon: "hand-time",
    title: t("service_page.save_time_on_administration"),
    text: t("service_page.get_a_cleaning_deal_that_is_easy_to_manage"),
    colorClass: "text-brand-600",
  },
  {
    id: 3,
    icon: "heart-bubbly",
    title: t("service_page.high_customer_satisfaction"),
    text: t("service_page.officeguru_trains_cleaning_partners"),
    colorClass: "text-[#BD4CD1]",
  },
];
</script>

<style scoped>
.card {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(111, 115, 130, 0.06);
}
</style>
