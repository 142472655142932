<template>
  <div
    class="flex w-full flex-col"
    :class="{ 'pt-10': isOfficehubImpersonation }"
  >
    <EmbeddedOfficehubImpersonationBar />
    <GodModeBar />
    <PageHeader />
    <NuxtPage />
    <PageFooter v-if="!isEmbedded" />
    <div data-teleport="compact-navigation" aria-hidden="true" />
    <div data-teleport="request-flow" aria-hidden="true" />
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useOfficehub } from "~/utils/use-officehub";

const { isOfficehubImpersonation } = useOfficehub();
const { isEmbedded } = useUIStore();
</script>
