import { ComponentOptions } from "vue";
import {
  CmsBlocksCategoryBlock,
  CmsBlocksImageText,
  CmsBlocksTextBox,
  CmsBlocksHeroWithSearch,
  CmsBlocksRelevantLinks,
  CmsBlocksServiceTemplateContact,
  CmsBlocksServiceTemplateDescription,
  CmsBlocksServiceTemplateHeader,
  CmsBlocksServiceTemplateLunchAd,
  CmsBlocksLogoBanderole,
  CmsBlocksHeroTextOnly,
  CmsBlocksFullWidthImage,
  CmsBlocksBaseColumns,
  CmsBlocksContactForm,
  CmsBlocksCustomerQuote,
  CmsBlocksSignupForm,
  CmsBlocksServiceCollection,
  CmsBlocksHeroSplit,
  CmsBlocksServiceCarousel,
  CmsBlocksRatingColumns,
  CmsBlocksComponentsBlogList,
  CmsBlocksFaqList,
  CmsBlocksFullWidthYoutube,
  CmsBlocksSendgridForm,
  CmsBlocksHubspotForm,
  CmsBlocks2ColumnComponents,
  CmsBlocksServicePopularCollection,
  CmsBlocksBannerBox,
  CmsBlocksServiceCollectionText,
  CmsBlocksHeaderSpacer,
} from "#components";

interface CmsBlockTypeComponentMap {
  [key: string]: ComponentOptions<any>;
}

/**
 * This maps a type from an object inside the `content` property of a backend response
 * to a Vue component that is then rendering the block. The backend type value is the key,
 * the value is the VueComponent.
 */
const CMS_BLOCK_TYPE_COMPONENT_MAP: CmsBlockTypeComponentMap = {
  text_and_image: CmsBlocksImageText,
  text: CmsBlocksTextBox,
  current_category: CmsBlocksCategoryBlock,
  hero_with_search: CmsBlocksHeroWithSearch,
  hero_split: CmsBlocksHeroSplit,
  relevant_links: CmsBlocksRelevantLinks,
  service_template_header: CmsBlocksServiceTemplateHeader,
  service_template_lunch_ad: CmsBlocksServiceTemplateLunchAd,
  service_template_description: CmsBlocksServiceTemplateDescription,
  service_template_contact: CmsBlocksServiceTemplateContact,
  logo_banderole: CmsBlocksLogoBanderole,
  hero_text: CmsBlocksHeroTextOnly,
  full_width_image: CmsBlocksFullWidthImage,
  columns: CmsBlocksBaseColumns,
  contact_form: CmsBlocksContactForm,
  customer_quote: CmsBlocksCustomerQuote,
  vendor_signup_form: CmsBlocksSignupForm,
  customer_signup_form: CmsBlocksSignupForm,
  service_carousel: CmsBlocksServiceCarousel,
  service_collection: CmsBlocksServiceCollection,
  service_collection_text: CmsBlocksServiceCollectionText,
  service_popular_collection: CmsBlocksServicePopularCollection,
  rating_columns: CmsBlocksRatingColumns,
  blog_list: CmsBlocksComponentsBlogList,
  faq_list: CmsBlocksFaqList,
  full_width_youtube: CmsBlocksFullWidthYoutube,
  sendgrid_form: CmsBlocksSendgridForm,
  hubspot_form: CmsBlocksHubspotForm,
  "2_column_components": CmsBlocks2ColumnComponents,
  banner: CmsBlocksBannerBox,
  header_spacer: CmsBlocksHeaderSpacer,
};

export { CMS_BLOCK_TYPE_COMPONENT_MAP };
