<template>
  <div
    class="typo-body mb-2 mt-4 flex rounded-lg border-semantic-info-100 bg-semantic-info-50 p-3 font-medium text-semantic-info-900"
  >
    <BaseIcon
      icon="info-filled"
      class="mr-3 shrink-0 text-semantic-info-600"
    />{{ text }}
  </div>
</template>

<script lang="ts" setup>
const { t: $t } = useI18n();

interface Props {
  amount: number;
  maxAmount: number;
  availableVendorsAmount: number;
}

const props = defineProps<Props>();

const text = computed(() => {
  if (
    props.amount >= props.maxAmount ||
    props.amount >= props.availableVendorsAmount
  )
    return $t("request_flow.no_more_vendors");
  if (props.amount > 0)
    return $t("request_flow.n_additional_vendors", {
      n: props.maxAmount - props.amount,
    });
  return $t("request_flow.up_to_n_vendors", { n: props.maxAmount });
});
</script>
