<template>
  <div class="border-b border-b-grey-200 py-10">
    <h2 class="typo-h5 mb-6 font-bold text-grey-900">
      {{ $t("vendor_page.services_provided") }}
    </h2>
    <div class="flex flex-col gap-4">
      <div
        v-for="(service, i) in sortedServices.slice(0, servicesShowing)"
        :key="service.id"
        class="flex gap-4 pt-6 first:pt-0"
        itemprop="makesOffer"
        itemscope
        itemtype="https://schema.org/Offer"
      >
        <div
          class="flex h-[48px] w-[48px] items-center justify-center rounded-lg bg-[#FEF8F2]"
        >
          <BaseIcon
            :icon="`service_${service.plain_text_id}` as BaseIconName"
            class="text-grey-500"
            color="#EE851F"
            :size="24"
          />
        </div>
        <div
          class="flex flex-1 flex-col border-b border-b-grey-200 pb-6"
          :class="{ 'border-b-0': i === sortedServices.length - 1 }"
        >
          <div class="flex flex-wrap gap-x-3 gap-y-2">
            <NuxtLink :to="serviceDetailPath(service.slug)">
              <h3
                class="typo-body mb-1 font-semibold text-grey-900"
                itemprop="name"
              >
                {{ service.name }}
              </h3>
            </NuxtLink>
            <BaseBadge
              v-if="service.bestseller"
              icon-name="bestseller"
              :text="$t('badges.bestseller')"
              class="mb-1"
            />
            <BaseBadge
              v-if="service.full_capacity"
              icon-name="hand-time"
              :text="$t('badges.full_capacity')"
              class="mb-1"
            />
          </div>
          <p
            class="typo-body text-grey-700"
            itemprop="disambiguatingDescription"
          >
            {{ service.excerpt }}
          </p>
          <SingleVendorServiceMetaRenderer :service="service" class="mt-6" />
        </div>
        <div v-if="REQUEST_FLOW_ACTIVATED">
          <BaseButton
            type="outline"
            size="small"
            class="whitespace-nowrap"
            @click="
              () =>
                showRequestFlow({
                  vendorId: vendor.id,
                  serviceId: service.id,
                  posthog: {
                    source: 'vendor_page',
                    trigger: 'list-button',
                  },
                })
            "
            >{{ $t("request_flow.get_offer") }}</BaseButton
          >
        </div>
      </div>
      <BaseButton
        v-if="servicesShowing < sortedServices.length"
        type="clear"
        class="self-center"
        @click="loadMore(10)"
      >
        {{ $t("vendor_page.load_count_more", { count: loadMoreCount() }) }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUIStore } from "~/store/UIStore";
import SingleVendorServiceMetaRenderer from "~/pages/components/vendor/single-vendor-service-meta-renderer.vue";
import { useCMSStore } from "~/store/CMSStore";
import serviceDetailPath from "~/utils/service-detail-path";
const { showRequestFlow } = useUIStore();
interface Props {
  vendor: OgApi.VendorFull;
}

const props = defineProps<Props>();

const { cmsValues } = useCMSStore();
const $route = useRoute();
const REQUEST_FLOW_ACTIVATED =
  cmsValues?.enable_request_flow || $route.query.rf;

const servicesShowing = ref(3);

const sortedServices = computed(() => {
  return [...props.vendor?.services].sort((a, b) => {
    if (a.bestseller) return -1;
    if (b.bestseller) return 1;
    return 0;
  });
});

function loadMoreCount() {
  return Math.min(10, sortedServices.value.length - servicesShowing.value);
}

function loadMore(amount: number) {
  servicesShowing.value += amount;
}
</script>
