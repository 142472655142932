<template>
  <BaseButtonV2 v-bind="translatedProps">
    <slot />
  </BaseButtonV2>
</template>

<script setup lang="ts">
interface Props {
  href?: string | null;
  blank?: boolean;
  type?: "primary" | "clear" | "outline";
  color?: "purple-brand" | "black" | "white" | "black-900";
  size?: "small" | "medium" | "large" | "x-large";
  buttonClasses?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  href: null,
  blank: false,
  type: "primary",
  color: "purple-brand",
  size: undefined,
  buttonClasses: "",
  disabled: false,
});

onMounted(() => {
  if (props.buttonClasses) {
    // eslint-disable-next-line no-console
    console.warn(
      "The `buttonClasses` prop has been deprecated and won't have any effect.",
    );
  }
});

const translatedProps = computed(() => {
  const typeMap = {
    primary: "primary",
    clear: "tertiary",
    outline: "secondary",
  };
  return {
    href: props.href,
    blank: props.blank,
    type: typeMap[props.type] as "primary" | "secondary" | "tertiary",
    size: translatedSize.value as "small" | "medium" | "large",
    disabled: props.disabled,
  };
});

const translatedSize = computed(() => {
  // new default value is large, so whatever was default (medium) before, should now be large
  if (!props.size) return "large";

  // we no longer have x-large, so large should be the new x-large
  if (props.size === "x-large") return "large";

  // take whatever size was provided
  return props.size;
});
</script>
