<template>
  <BaseContent
    :style="`${backgroundColor}`"
    content-classes="!p-0 !m-0"
    class="pt-20"
  />
</template>

<script setup lang="ts">
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const backgroundColor = useBackgroundColor(props);
</script>
