<template>
  <div class="mx-auto flex max-w-[400px] animate-redraw flex-col">
    <h2 class="typo-h5 mb-2 text-grey-900">Welcome back!</h2>
    <div class="typo-body mb-8 mt-4 rounded-lg bg-grey-50 p-4 font-semibold">
      <div class="flex items-center">
        <BaseIcon icon="user" class="mr-3 text-brand-600" :size="14" />
        {{ $t("request_flow.dont_have_account_already") }}
        <span
          role="button"
          class="ml-1 cursor-pointer font-medium text-brand-600"
          @click="$emit('show-login', false)"
          >{{ $t("request_flow.sign_up") }}</span
        >
      </div>
    </div>
    <div class="flex flex-col">
      <label class="flex flex-col text-body text-grey-700">
        <span class="mb-1">{{ $t("request_flow.email") }}</span>
        <input
          v-model="email"
          type="text"
          :placeholder="$t('request_flow.email_placeholder')"
          class="rounded-lg border border-grey-300 px-4 py-2.5 text-body"
          :class="{
            'border-semantic-negative-700 bg-semantic-negative-50':
              !!getErrorsForField('email'),
          }"
        />
        <span
          v-if="!!getErrorsForField('email')"
          class="typo-caption mt-1 flex items-baseline text-semantic-negative-700"
          ><BaseIcon class="mr-1" icon="close" :size="12" color="red" />
          {{ getErrorsForField("email") }}</span
        >
      </label>
      <label class="mt-4 flex flex-col text-body text-grey-700">
        <span class="mb-1">{{ $t("request_flow.password") }}</span>
        <input
          v-model="password"
          type="password"
          autocomplete="current-password"
          class="rounded-lg border border-grey-300 px-4 py-2.5 text-body"
          :class="{
            'border-semantic-negative-700 bg-semantic-negative-50':
              !!getErrorsForField('password'),
          }"
        />
        <span
          v-if="!!getErrorsForField('password')"
          class="typo-caption mt-1 flex items-baseline text-semantic-negative-700"
          ><BaseIcon class="mr-1" icon="close" :size="12" color="red" />
          {{ getErrorsForField("password") }}</span
        >
      </label>
      <div class="mt-3 flex">
        <a
          :href="passwordResetUrl"
          target="_blank"
          class="typo-body font-medium text-brand-600"
          >{{ $t("forgot_password") }}</a
        >
      </div>
      <div class="flex flex-col items-center">
        <BaseButton
          class="mt-8 w-full"
          :disabled="loginLoading || isLoggedIn"
          @click="login({ email, password })"
        >
          <template v-if="!loginLoading && !isLoggedIn">{{
            $t("request_flow.login_and_get_offer")
          }}</template>
          <template v-else>
            {{ $t("request_flow.logging_in") }}
            <BaseIcon
              color="white"
              icon="loading"
              class="ml-1 animate-spin" /></template
        ></BaseButton>
        <span class="typo-caption mt-2 block text-grey-500">{{
          $t("request_flow.billed_after_completion")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/AuthStore";
const { t: $t } = useI18n();
const passwordResetUrl = `${useRuntimeConfig().public.hubUrl}password/reset`;

const emit = defineEmits(["success", "show-login"]);
const email = ref("");
const password = ref("");
const { login } = useAuthStore();
const { token, loginLoading, loginErrors, isLoggedIn } = storeToRefs(
  useAuthStore(),
);

function getErrorsForField(name: string) {
  if (Object.keys(loginErrors.value).length < 1) return;

  const errors = loginErrors.value[name as keyof typeof loginErrors.value];

  if (!Array.isArray(errors)) return;

  return (errors as [])
    .map((error: any) => {
      return $t(`login.${name}.${error.identifier}`);
    })
    .join(" & ");
}

watch(
  () => token.value,
  (token) => {
    if (token) {
      emit("success");
    }
  },
);
</script>
