<template>
  <div
    class="sticky bottom-0 flex items-center justify-center border-t border-t-grey-100 bg-white p-4 shadow-subtle-top"
  >
    <BaseButton class="w-full font-bold" @click="showMobileContactForm">
      {{ $t("service_page.get_your_offer_now") }}
    </BaseButton>
    <div
      :class="mobileContactFormClasses"
      class="fixed left-0 top-0 flex h-full w-full flex-col bg-white transition-transform duration-200"
    >
      <div class="flex items-center justify-between p-4">
        <p class="typo-h5 font-semibold text-grey-700">
          {{ $t("service_page.contact") }}
        </p>
        <BaseIcon
          icon="close"
          class="cursor-pointer"
          @click="hideMobileContactForm"
        />
      </div>
      <div class="flex-1 overflow-auto">
        <WrappedContactForm
          :vendor="vendor"
          :service="service"
          class="mx-4 mt-4"
          :create-account-link="createAccountLink"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUIStore } from "~/store/UIStore";
import WrappedContactForm from "~/pages/components/wrapped-contact-form.vue";

defineProps({
  service: {
    type: Object as PropType<Service>,
    default: null,
  },
  vendor: {
    type: Object,
    default: null,
  },
  createAccountLink: {
    type: String,
    default: null,
  },
});

const uiStore = useUIStore();
const mobileContactFormShowing = ref(false);

const mobileContactFormClasses = computed(() => {
  return mobileContactFormShowing.value ? "translate-y-0" : "translate-y-full";
});

function showMobileContactForm() {
  uiStore.hideMainNav();
  uiStore.hideFooter();
  uiStore.disableScroll();
  mobileContactFormShowing.value = true;
}

function hideMobileContactForm() {
  uiStore.showMainNav();
  uiStore.showFooter();
  uiStore.enableScroll();
  mobileContactFormShowing.value = false;
}
</script>
