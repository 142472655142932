<template>
  <BaseContent
    class="fixed z-50"
    content-classes="!pb-0 !pt-4"
    tag="header"
    reduced-padding
  >
    <div
      class="page-header-outer-container flex max-h-16 justify-between rounded-lg border border-black/5 lg:border-none"
    >
      <div
        class="flex items-center rounded-lg p-3 pr-2 md:p-4 lg:border lg:border-black/5 lg:bg-white/75 lg:backdrop-blur-lg"
      >
        <NuxtLink :to="`/${lang}`" class="mr-5 flex shrink-0">
          <img
            :src="ogLogo"
            alt="Officeguru logo - letter O and G intertwining into an infinity symbol"
            width="46"
            height="24"
          />
        </NuxtLink>
        <GlobalSearch compact />
      </div>
      <div
        class="flex items-center gap-0 rounded-lg py-2.5 pl-2 pr-3 md:px-5 lg:flex-row-reverse lg:gap-4 lg:border lg:border-black/5 lg:bg-white/75 lg:backdrop-blur-lg"
      >
        <PageHeaderCartLink />
        <MainMenu :items="props.navigationItems as NavigationItem[]" />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { PageHeaderProps } from "~/types/component-props";
import ogLogo from "~/assets/og-logo.svg?url";
import { preloadImage } from "#imports";
const lang = globalThis.lang;

const props = defineProps<PageHeaderProps>();

preloadImage(ogLogo as string);
</script>

<style scoped>
/*
  We have to do this here because of how responsiveness in TW and backdrop-blur in browsers work.
  The original implementation was just setting the backdrop-blur on the outer element for sm + md and overriding
  it with none / transparent on lg, but this breaks the inner backdrop-blur on the two separate elements (that we have
  when going to lg breakpoint), because we then kill all backdrop-blur via the outer element. This here only applies
  it below the lg breakpoint so that we don't have to override it when going to lg.
*/
@media (max-width: 959px) {
  .page-header-outer-container {
    @apply bg-white/75 backdrop-blur-lg;
  }
}
</style>
