<template>
  <component :is="isNested ? 'div' : BaseContent" :style="backgroundStyle">
    <div class="flex flex-col" :class="horizontalAlignment">
      <div
        class="w-full rounded-lg bg-white p-8 pb-5 shadow-alternative-soft-glow md:max-w-[467px]"
      >
        <h2 v-if="headline" class="typo-h5 mb-6">{{ headline }}</h2>
        <div :id="containerId" :class="[verticalPadding]" />
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { useBackgroundImage } from "~/components/cms-blocks/components/use-background-image";
import { randomId } from "~/utils/random-id";
import { BaseContent } from "#components";
import "~/assets/css/hubspot-form.css";
import { useHorizontalAlignment } from "~/components/cms-blocks/components/use-horizontal-alignment";

const HUBSPOT_SCRIPT_URL = "//js-eu1.hsforms.net/forms/embed/v2.js";
const containerId = ref();

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  isNested: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  containerId.value = randomId();

  if (!document.querySelector(`script[src="${HUBSPOT_SCRIPT_URL}"]`)) {
    const script = document.createElement("script");
    script.src = HUBSPOT_SCRIPT_URL;
    script.type = "text/javascript";
    script.async = true;

    script.onload = createForm;

    document.head.appendChild(script);

    return;
  }

  createForm();
});

function createForm() {
  globalThis.hbspt.forms.create({
    region: "eu1",
    portalId: "25262360",
    formId: formId.value,
    target: `#${containerId.value}`,
  });
}

const formId = computed(() => {
  return props.content.form_id;
});

const headline = computed(() => {
  return props.content.headline;
});

const horizontalAlignment = useHorizontalAlignment(props);
const verticalPadding = useVerticalPadding(props);
const backgroundColor = useBackgroundColor(props);
const backgroundImage = useBackgroundImage(props);
const backgroundStyle = backgroundImage.value
  ? backgroundImage.value
  : backgroundColor.value;
</script>
