<template>
  <NuxtLink
    v-if="cmsValues?.link_customer_signup"
    :to="{
      name: cmsValues.link_customer_signup,
    }"
  >
    <BaseButtonV2 class="whitespace-nowrap" v-bind="$attrs" blank>
      {{ $t("components.signup") }}
    </BaseButtonV2>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useCMSStore } from "~/store/CMSStore";

const { cmsValues } = useCMSStore();
</script>
