<template>
  <div class="flex flex-col border-b border-b-grey-200 pb-10">
    <h3 class="typo-body mb-2 font-semibold text-grey-900">
      {{ $t("vendor_page.overview") }}
    </h3>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
      <div
        v-if="vendor.badges"
        class="flex items-start gap-2 empty:hidden md:flex-col"
      >
        <VendorBadges
          :badges="vendor.badges"
          class="flex flex-wrap gap-2 md:flex-col"
        />
      </div>
      <div
        v-if="yearsInBusiness || vendor.number_of_employees"
        class="flex flex-wrap items-start gap-2 md:flex-col"
      >
        <VendorBadge
          v-if="yearsInBusiness"
          class="max-w-full truncate"
          :label="`${yearsInBusiness} ${lowerFirst(
            $t('vendor_page.years_in_business'),
          )}`"
        />
        <VendorBadge
          v-if="vendor.number_of_employees || vendor.number_of_employees === 0"
          :label="`${vendor.number_of_employees} ${lowerFirst(
            $t('vendor_page.employees'),
          )}`"
          class="max-w-full truncate"
          itemprop="numberOfEmployees"
          itemscope
          itemtype="https://schema.org/QuantitativeValue"
        />
      </div>
      <div
        class="flex items-center gap-4"
        itemprop="employee"
        itemscope
        itemtype="https://schema.org/Person"
      >
        <div
          v-if="vendorProfileImage || ownerInitials"
          class="typo-body flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full border border-grey-200 bg-grey-50 bg-cover bg-center text-semantic-info-800"
          :style="vendorProfileImage"
        >
          <span v-if="!vendorProfileImage">{{ ownerInitials }}</span>
          <meta
            v-if="vendorProfileImage"
            itemprop="image"
            :content="vendor?.owner?.image || ''"
          />
        </div>
        <div class="flex flex-col items-start">
          <p class="typo-body text-grey-900">
            {{ $t("vendor_page.account_owner") }}
          </p>
          <p class="typo-body text-grey-500" itemprop="name">
            {{ vendor?.owner?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import lowerFirst from "lodash/lowerFirst";
import { useCmsImage } from "~/utils/use-cms-image";
const props = defineProps<{
  vendor: OgApi.VendorFull;
}>();

const vendorProfileImage = computed(() => {
  return props.vendor?.owner?.image
    ? `background-image: url("${
        useCmsImage({
          src: props.vendor.owner.image,
          params: "width=48&height=48",
        }).value
      }")`
    : "";
});

const yearsInBusiness = computed(() => {
  if (!props.vendor?.establishing_year) return null;
  return new Date().getFullYear() - props.vendor?.establishing_year;
});

const ownerInitials = computed(() => {
  return props.vendor?.owner?.name
    ? props.vendor.owner.name
        .split(" ")
        .slice(0, 3)
        .map((name: string) => name[0].toUpperCase())
        .join("")
    : "";
});
</script>
