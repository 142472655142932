<template>
  <div class="flex h-full w-full items-center justify-between px-4">
    <template v-if="!uiStore.mobileCustomerAppNavIsShown">
      <div
        class="flex w-[50px] cursor-pointer self-center text-grey-700"
        @click="uiStore.showMobileCustomerNav()"
      >
        <BaseIcon icon="customer-app-navigation-menu" :size="24" />
      </div>
      <EmbeddedCustomerAppNavigationOgLogo
        class="flex h-auto w-[36px] self-center"
      />
      <div class="flex w-[50px] self-start"></div>
    </template>
  </div>
  <ClientOnly>
    <!-- had issues with the stacking contexts so best solution I found was teleporting it to the end of the body -->
    <Teleport to="[data-teleport=customer-app-mobile-navigation-flyout]">
      <div
        v-if="uiStore.mobileCustomerAppNavIsShown"
        class="fixed left-0 top-0 z-[200] h-screen w-screen"
      >
        <div
          class="fixed left-0 top-0 z-50 h-screen w-screen bg-[rgba(0,0,0,0.5)]"
          @click="uiStore.hideMobileCustomerNav()"
        ></div>
        <div
          class="fixed z-[55] flex h-screen w-[192px] border-r border-grey-100 bg-grey-50 text-grey-300"
        >
          <EmbeddedCustomerAppNavigationLg :items="items" :show-close="true" />
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>
<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const uiStore = useUIStore();

interface Props {
  items: CustomerApp.NavigationItems;
}

defineProps<Props>();

function onWindowResize() {
  uiStore.hideMobileCustomerNav();
}

onMounted(() => {
  // hide menu when resizing so that we don't accidentally keep it open while entering the larger breakpoints
  // where it shouldn't exist
  globalThis.addEventListener("resize", onWindowResize);
});

onUnmounted(() => {
  globalThis.removeEventListener("resize", onWindowResize);
});
</script>
