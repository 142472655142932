{
  "all_services": "All services",
  "badges": {
    "bestseller": "Bestseller",
    "fast_response": "Fast response",
    "full_capacity": "Full capacity",
    "high_demand": "High demand",
    "new_vendor": "New vendor",
    "responds_day": "Typical responds within a day",
    "responds_few_days": "Typical responds within 1-2 days",
    "responds_few_more_days": "Typical responds within 3-4 days",
    "responds_hours": "Typical responds within a couple of hours",
    "responds_week": "Typical responds within a week",
    "top_vendor": "Top vendor"
  },
  "blocks": {
    "rating_columns": {
      "name_rated": "{name} rated"
    }
  },
  "blog": {
    "confirm": "Confirm",
    "enter_your_email": "Enter your email",
    "next_recommended_read": "Next recommended read",
    "officeguru_can_store_my_data": "Subscribe to our newsletter and get first-hand information about exciting new updates and features",
    "others_have_read": "Others have read",
    "sign_up_for_newsletter": "Sign up for newsletter"
  },
  "cart": {
    "cancel": "Cancel",
    "cart": "Cart",
    "cart_changed_verify_content": "The content of your cart has been changed, please make sure to verify the content of your cart before making a purchase.",
    "checkout": "Checkout",
    "delivery_fee": "Delivery fee",
    "did_you_know": "Did you know?",
    "discounts": {
      "add": "Add",
      "add_code_failed": "Adding code failed",
      "cancel": "Cancel",
      "code": "Discount code",
      "code_placeholder": "e.g. SALE23",
      "discount_free_delivery": "Discount - free delivery",
      "discount_n_percent_off": "Discount - {number}% off",
      "errors": {
        "error_og_discounts_distinct": "The discount code can only be used once",
        "error_og_discounts_invalid": "The discount code is invalid",
        "error_og_discounts_only_one_each_type": "You can only use one discount code of each type"
      },
      "no_longer_available": "Discount(s) no longer available",
      "no_longer_available_description": "Some discounts on your order are no longer available. Please remove them and try again.",
      "remove_code_failed": "Removing code failed",
      "unknown_error": "Unknown error",
      "validating_cart_failed": "Validating the cart failed."
    },
    "general_error": "Something went wrong with your cart. If the problem persists, contact our support.",
    "go_to_cart": "Go to cart",
    "go_to_marketplace": "Go to marketplace",
    "officeguru_protection": "Officeguru Protection",
    "pricing": "Pricing",
    "service_fee": "Service fee (1.79%)",
    "service_fee_percentage": "Service fee ({percentage}%)",
    "subtotal": "Subtotal",
    "total_in_dkk_incl_vat": "Total in DKK (incl. VAT)",
    "vat": "VAT (25%)",
    "you_can_buy_products_from_vendors": "You can buy products from vendors directly from their profile. Just go to the marketplace and find a vendor that sells products.",
    "you_can_only_order_from_one_vendor": "You can only order products from one vendor at a time. Your current cart will be emptied if you add this product."
  },
  "cart.add_to_cart": "Add to cart",
  "cart.add_to_cart_this_will_empty": "Add to cart? This will empty the current cart",
  "cart.your_cart_is_empty": "Your cart is empty",
  "checkout": {
    "add_a_not_description": "If you have any special requests, please write them here",
    "add_a_note": "Add a note",
    "address": "Address",
    "delivery": "Delivery",
    "i_want_to_receive_marketing_content_and_newsletters": "Subscribe to our newsletter and get first-hand information about exciting new updates and features",
    "note_placeholder": "e.g. special requests or allergens",
    "optional": "optional",
    "order_overview": "Order overview",
    "place_order": "Place order",
    "preferred_delivery_date": "Preferred delivery date",
    "the_desired_delivery_date_can_be_adjusted": "The desired delivery date can be adjusted by the vendor.",
    "you_will_receive_an_invoice": "You will receive an invoice, when your order has been accepted."
  },
  "components": {
    "datepicker": {
      "select_a_date": "Select a date"
    },
    "footer": {
      "gazelle_winner": "Børsen Gazelle winner for 7 years in a row."
    },
    "login": "Login",
    "select_dropdown": {
      "select_an_option": "Select an option"
    },
    "service_search": {
      "find_a_service": "Search services",
      "no_results": "No results",
      "popular_services": "Popular services",
      "search": "Search",
      "zip": "Zip"
    },
    "signup": "Sign up"
  },
  "customer": {
    "calendar": {
      "title": "Calendar"
    },
    "home": {
      "title": "Home"
    },
    "marketplace": "Marketplace",
    "messages": "Messages",
    "orders": {
      "title": "Orders"
    },
    "partners_list": {
      "title": "Vendors"
    },
    "settings": {
      "company_settings": "Settings"
    },
    "tasks": {
      "title": "Tasks"
    }
  },
  "customer_signup_form": {
    "already_have_account": "Already have an account?",
    "button": {
      "submit": "Create a free account"
    },
    "contact_us": "Contact us",
    "creating_account": "Creating account",
    "customer": {
      "address": {
        "error_required": "Address is required."
      },
      "email": {
        "error_email": "Email needs to be a valid email",
        "error_required": "Email is required.",
        "error_unique": "Email is already being used."
      },
      "name": {
        "error_required": "Customer name is required.",
        "error_unique": "Customer name is already taken"
      },
      "person": {
        "error_required": "Name is required."
      },
      "zip": {
        "error_between_numeric": "Zip needs to be between 1050 and 9990",
        "error_integer": "Zip needs to consist of numbers only",
        "error_required": "Zip is required."
      }
    },
    "generic_error": "Sorry, we could not send the form. Please try again in a few seconds or contact us for help.",
    "go_to_login": "Go to login",
    "label": {
      "address": "Address",
      "company": "Company",
      "email": "Email",
      "full_name": "Name",
      "i_have_read_and_accept": "I have read and accept the",
      "marketing_content_and_newsletter": "Subscribe to our newsletter and get first-hand information about exciting new updates and features",
      "password": "Password",
      "phone": "Phone number",
      "terms_at": "I have read and accept the <a href=\"/en/terms\" target=\"blank\" rel=\"noopener\">terms of service</a>",
      "terms_of_service": "terms of service",
      "zip": "Zip"
    },
    "log_in": "Log in",
    "placeholder": {
      "address": "e.g. Bankgade 12",
      "company": "e.g. Fynske Bank",
      "email": "e.g. hans{'@'}fb.dk",
      "full_name": "e.g. Hans Petersen",
      "password": "We'll keep this a secret",
      "phone": "e.g. 45452323",
      "zip": "e.g. 1224"
    },
    "sign_up_it_is_free": "Sign up today - it’s free",
    "start_getting_control": "Start getting control of your workplace today.",
    "user": {
      "email": {
        "error_email": "Email needs to be a valid email",
        "error_required": "Email is required.",
        "error_unique": "Email is already being used."
      },
      "password": {
        "error_required": "Password is required."
      }
    },
    "we_are_happy_you_are_part_of_og": "Success! We are happy to say that you are now part of Officeguru",
    "you_can_now_log_in": "You can now log in and start enjoying the benefits of the platform"
  },
  "forgot_password": "Forgot your password?",
  "go_to_app": "Go to app",
  "httpErrors": {
    "base": {
      "contact_us": "contact us",
      "go_to_home": "Take me home",
      "go_to_marketplace": "Go to marketplace",
      "go_to_products": "Go to products",
      "go_to_services": "Go to services",
      "go_to_vendor": "Go to vendor",
      "or_if_you_need_help": "Or if you need help"
    },
    "fallthrough": {
      "title": "We <em>can't find the page</em> you are looking for."
    },
    "generic_500": {
      "description": "We are working on fixing this. In the meantime, let’s get you home.",
      "title": "Sorry, we had an <em>unexpected error</em>"
    },
    "unknown_product": {
      "description_no_vendor": "The vendor does not exist. Go to services to find other vendors that sell the product you need.",
      "description_vendor_exists": "Go to the vendors profile to see what they offer.",
      "title": "We <em>can't find the product</em> you are looking for."
    },
    "unknown_product_overview": {
      "description_no_vendor": "The vendor does not exist. Go to services to find other vendors that sell the product you need.",
      "description_vendor_exists": "The vendor does not sell products. Go to services to find other vendors that sell the product you need.",
      "title": "We <em>can't find the product list</em> you are looking for."
    },
    "unknown_service": {
      "description": "Go to services to find services that fit your needs.",
      "title": "We <em>can't find the service</em> you are looking for."
    },
    "unknown_vendor": {
      "description": "Go to marketplace to find other vendors that fit your needs.",
      "title": "We <em>can't find the vendor</em> you are looking for."
    }
  },
  "login": {
    "email": {
      "error_email": "Email needs to be a valid email",
      "error_og_credentials": "Email or password wrong.",
      "error_og_login_user_type_customer": "This email does not belong to a customer user",
      "error_required": "Email is required.",
      "error_unique": "Email is already being used."
    },
    "password": {
      "error_required": "Password is required."
    }
  },
  "officehub": {
    "close": "Close",
    "managed_by": "Managed by",
    "managed_by_tooltip": "Explainer of sub-accounts",
    "viewing_as_name": "Viewing as {name}"
  },
  "pageError": {
    "404_description": "We searched in all the corners but we couldn’t find this page. Let’s get you home.",
    "404_title": "Sorry, this page does not exist",
    "500_description": "We are working on fixing this. In the meantime, let’s get you home.",
    "500_title": "Sorry, we had an unexpected error",
    "take_me_home": "Take me home"
  },
  "product_page": {
    "add_to_cart": "Add to cart",
    "added_x_to_cart": "Added {name} to cart",
    "added_x_y_to_cart": "Added {name} {count}x to cart",
    "price_excl_vat": "Price (excl. VAT)"
  },
  "request_flow": {
    "add_more": "+ Tilfoj flere",
    "almost_there_sign_up": "Sign up or log in",
    "back": "Back",
    "billed_after_completion": "You get billed after task completion",
    "call_us": "Call us",
    "call_us_for_help": "call us for help",
    "cancel": "Cancel",
    "choose_service": "Select service",
    "choose_up_to_3": "Select up to 3.",
    "confirm_and_send": "Confirm and send",
    "confirm_your_details_before_we_send": "Confirm your details, before we send it to the partner | Confirm your details, before we send it to the partners",
    "days": {
      "friday": "Fri",
      "monday": "Mon",
      "saturday": "Sat",
      "sunday": "Sun",
      "thursday": "Thu",
      "tuesday": "Tue",
      "wednesday": "Wed"
    },
    "do_you_already_have_an_account": "Do you already have an account?",
    "dont_have_account_already": "Don't have an account?",
    "dont_hesitate_to_call": "Do not hesitate to call us, we will do our best to help you.",
    "drag_file_here": "Drag file(s) here or click to upload",
    "edit": "Edit",
    "email": "Email",
    "email_placeholder": "navn{'@'}firma.dk",
    "free_wo_strings": "It's free and without strings attached",
    "get_an_offer": "Get an offer",
    "get_offer": "Get offer",
    "get_offer_for_service": "Get an offer for {service}",
    "get_offer_from_vendor": "Get an offer from {vendor}",
    "go_to_chat_support": "go to chat support",
    "issue_on_our_end_working_on_it": "It seems there was an issue on our end while processing your request. Our team has been notified, and we're working to resolve the problem.",
    "locations_loading": " Locations loading... ",
    "log_in": "Log in",
    "logging_in": "Logging in",
    "login_and_get_offer": "Log in",
    "min_pax_count": "Min. pax {count}",
    "n_additional_vendors": "You can select {n} additional vendors, to get more offers to choose from.",
    "new_on_officeguru": "New on Officeguru",
    "next": "Next",
    "no_more_vendors": "You can’t select any more vendors.",
    "no_vendors_deliver_to_address": "Aw snap, no vendors deliver to {address}",
    "now_drop": "Now let the file(s) go to upload",
    "offer_for_service": "Offer for {service}",
    "optional": " (valgfrit)",
    "or": "or",
    "password": "Password",
    "select_n_vendors": "Select {count} vendor(s)",
    "select_service": "Select service",
    "select_service_for_offer": "Select the service you want an offer for.",
    "select_service_placeholder": "Select service",
    "select_vendor": "Select vendor",
    "select_vendors": "Select vendor(s)",
    "select_vendors_near": "Select vendors near",
    "selected": "Selected",
    "sending_request": "Hang on, we are sending your offer request to our partners",
    "sign_up": "Sign up",
    "sorry_something_went_wrong": "Sorry, something went wrong!",
    "sure_you_want_to_cancel": "Close offer request? You will loose all information you have inputted.",
    "try_again": "Try again",
    "trying_again": "Trying again...",
    "up_to_n_vendors": "Select up to {n} vendors.",
    "view_profile": "View profile",
    "we_might_have_vendors_that_deliver_to_other_location": "We might have vendors that deliver to your other locations - please select a location:",
    "we_will_ask_few_questions": "Answer a few questions and get up to 3 non-commiting offers.",
    "what_vendors_would_you_like_offers_from": "Which partners do you want offers from?",
    "which_location_to_deliver_to": "Which location do you want your delivery to?",
    "which_vendors_in_address_would_you_like": "Which vendors in {address} would you like offers from?",
    "you_would_like_offers_from": "you would like offers from"
  },
  "search": {
    "clear_all": "Clear all",
    "filters": "Filters",
    "find_anything": "Search marketplace",
    "loading": "Loading",
    "marketplace": "Marketplace",
    "no_products_match_your_filters": "No products match your filters and search terms.",
    "no_results_found": "No results found",
    "no_services_match_your_filters": "No services match your filters and search terms.",
    "no_vendors_match_your_filters": "No vendors match your filters and search terms.",
    "partners": "Vendors",
    "products": "Products",
    "remove_filters": "Clear all filters",
    "search": "Search",
    "search_placeholder": "Search the marketplace",
    "search_results": "Search results",
    "search_results_for_term": "Search results for ‘{term}’",
    "services": "Services",
    "show_all_results": "Show all results",
    "something_went_wrong": "Something went wrong",
    "start_typing_to_search": "Start typing to search...",
    "type": "Type",
    "vendor_name": "Vendor",
    "vendor_or_product_not_found": "Unfortunately, we couldn't find the product or vendor you were looking for. Use the search to find what you need on the marketplace.",
    "vendor_or_product_not_found_header": "Item not found",
    "vendors": "Vendors",
    "we_could_not_find_any_results": "We couldn't find any results that match your filters and search terms. Try a different search or:"
  },
  "service_page": {
    "a_quick_chat_to_hear_more_about_your_company": "A quick chat to hear more about your company's wishes",
    "always_available": "High quality and fair prices",
    "availability_is_a_prerequisite_for_a_good_partnership": "You can easily get offers and compare prices. All vendors strive towards a 5-star rating, ensuring the best quality for you.",
    "click_to_try_again": "Click here to try again",
    "company": "Company",
    "contact": "Contact",
    "contact_me_about_offers_today": "Contact me about offers today",
    "contact_me_today": "Contact me today",
    "count_ratings": "{count} reviews",
    "create_account": "Create account",
    "create_your_account": "Create your account",
    "deliver_to_zip": "Delivers to ZIP",
    "delivered_by": "Delivered by",
    "delivers_to_you": "Delivers to you",
    "delivers_to_zip": "Delivers to {zip}",
    "description": "Description",
    "email": "Email",
    "error_loading_vendors": "Sorry, there was an error loading the vendors.",
    "faq": "FAQ",
    "frequently_asked_questions": "Frequently asked questions",
    "full_name": "Full name",
    "get_a_cleaning_deal_that_is_easy_to_manage": "All communication is collected in a shared inbox, where you and your colleagues can coordinate and keep an overview of all agreements.",
    "get_started": "Get started",
    "get_your_offer_now": "Get your offer now",
    "high_customer_satisfaction": "High customer satisfaction",
    "i_accept_officeguru_stores_information": "I accept that Officeguru stores my information and may contact me.",
    "input_zip": "Input ZIP",
    "learn_more": "Learn more",
    "marketplace": "Marketplace",
    "min_pax_count": "Min. pax {count}",
    "near_you": "near you",
    "no_ratings": "No reviews yet",
    "no_vendors_can_deliver_to_your_zip": "No vendors can deliver this service to your provided zip code",
    "officeguru_protection": "Officeguru Protection",
    "officeguru_protection_description": "With Officeguru, you are guaranteed excellent service, high quality, and competitive prices. Our vetted vendors and user-friendly terms make switching between them effortless. We offer coverage for damages (compensation is subject to insurance limits), and our Customer Success team is always available to provide support.",
    "officeguru_trains_cleaning_partners": "All our vendors share one set of terms and no matter if you have one or multiple services, you get one collected invoice from Officeguru.",
    "or": "Or",
    "others_also_bought": "Others also bought",
    "others_bought": "Others bought",
    "phone_number": "Phone number",
    "read_more": "Read more",
    "review_your_offer": "Review your offer",
    "reviews": "Reviews",
    "reviews_of_service": "Reviews of {service}",
    "save_time_on_administration": "Save time on administration",
    "sign_up_get_offers": "Sign up and get offers from local vendors",
    "something_went_wrong": "Something went wrong",
    "sort_alphabetically": "Sort alphabetically",
    "sort_by_top_rated": "Sort by top rated",
    "the_vendor_will_send_you_an_offer": "The vendor will send you an offer that fits your needs",
    "type_vendors": "{type} vendors",
    "valid_email": "Email must be a valid format.",
    "vendor_tags": "Tags",
    "vendors": "Vendors",
    "view": "View profile",
    "want_to_get_started_by_yourself": "Want to get started by yourself?",
    "we_have_received_your_request": "We have received your request",
    "we_will_contact_you_in_1_2_days": "We will contact you within 1-2 days",
    "why_officeguru_is_right_for_you": "Why Officeguru is right for you",
    "you_will_be_contacted_by_specialists": "You will be contacted by on of our specialists"
  },
  "shared": {
    "n_current_of_n_total": "{current} of {total}",
    "showing_n_to_m_of_total_resource": "Showing {from} to {to} of {total} {resource}"
  },
  "vendor_page": {
    "about": "About",
    "account_owner": "Responsible",
    "all_products": "All products",
    "answer_from_name": "Answer from {name}",
    "average_rating": "Average rating",
    "categories": "Categories",
    "click_here_to_refresh": "Click here to refresh.",
    "close": "Close",
    "count_ratings": "{count} reviews",
    "delivers_to_you": "Delivers to you",
    "employees": "Employees",
    "example_menu": "Example menu",
    "faq": "FAQ",
    "faq_company_certifications": "What certifications does your company have?",
    "faq_company_consistency_and_quality": "How does your company ensure consistent and high quality?",
    "faq_company_sustainability": "How does your company work with sustainability?",
    "faq_kind_of_customers": "What kind of customers have you worked with?",
    "faq_working_environment": "How does your company ensure a good working environment?",
    "filter_by_item": "Filter by {item}",
    "filter_by_service": "Filter by service",
    "filtering_by_item": "Filtering by {item}",
    "filtering_by_label": "Filtering by {label}",
    "filters": "Filters",
    "guruscore_tooltip": "The Guruscore is calculated based on customers' reviews on the marketplace, and uses a Bayesian average to make it more comparable.",
    "latest_5_reviews": "Latest 5 reviews",
    "latest_count_reviews": "Latest {count} reviews",
    "latest_reviews": "Latest reviews",
    "load_count_more": "Load {count} more",
    "loading_of_products_failed": "Loading of products failed.",
    "marketplace": "Marketplace",
    "minimum_order": "Minimum order",
    "no_reviews_match_your_filters": "No reviews match your current filters",
    "on_date": "On {date}",
    "operational_areas": "Operational areas",
    "organic_badge": "Organic badge",
    "organic_bronze": "30-60% organic",
    "organic_gold": "90-100% organic",
    "organic_silver": "60-90% organic",
    "overview": "Overview",
    "price": "Price",
    "products": "Products",
    "products_description": "Find products for your office.",
    "rated": "Reviewed",
    "rating_by_percentage": "Filter by rating",
    "ratings": "Reviews",
    "review": "Review",
    "services": "Services",
    "services_provided": "Services provided",
    "smiley_report": "Smiley report",
    "star_rating_x": "{rating}-star rating",
    "tags": "Tags",
    "terms_of_payment": "Terms of payment",
    "terms_of_payment_description": "Services are paid monthly and due 8 days from invoice date. At Officeguru the terms and conditions are the same for all services.",
    "vendors": "Vendors",
    "verification_description": "The verification badge means that we have approved this vendor and their services and can ensure quality.",
    "verification_details": "Verification details",
    "verified": "Verified",
    "view": "View",
    "view_all": "View all",
    "view_all_images": "View all images",
    "view_menu": "View menu",
    "view_terms_of_payment": "View terms of payment",
    "would_hire_again": "Would hire again",
    "years_in_business": "Years in business",
    "zip_ranges": "Zip ranges"
  },
  "vendor_signup_form": {
    "apply_for_free": "Apply for free and start growing your business.",
    "button": {
      "submit": "Get started for free"
    },
    "contact_us": "Contact us",
    "generic_error": "Sorry, we could not send the form. Please try again in a few seconds or contact us for help.",
    "get_new_customers_today": "Get new customers today",
    "label": {
      "company": "Company",
      "email": "Email",
      "marketing_content_and_newsletter": "Subscribe to our newsletter and get first-hand information about exciting new updates and features",
      "name": "Name",
      "phone": "Phone",
      "what_services": "What services do you offer?"
    },
    "partner": {
      "description": {
        "error_required": "Partner description is required."
      },
      "name": {
        "error_required": "Name is required.",
        "error_unique": "Partner name is already taken"
      }
    },
    "placeholder": {
      "company": "e.g. Fynske Bank",
      "email": "e.g. hans{'@'}example.com",
      "name": "e.g. Hans Petersen",
      "phone": "e.g. 45452323",
      "what_services": "e.g. lunch, cleaning"
    },
    "thanks_for_application": "Thank you! We have received your application",
    "user": {
      "email": {
        "error_email": "Email needs to be a valid email",
        "error_required": "Email is required.",
        "error_unique": "Email is already being used."
      },
      "phone": {
        "error_required": "Phone number is required."
      }
    },
    "we_will_contact_you": "We will contact you as soon as possible."
  }
}
