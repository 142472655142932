<template>
  <div
    v-if="hasAnyContent"
    class="flex items-center justify-between"
    :class="smallFont ? 'mb-4' : 'mb-8 md:mb-10 lg:mb-12'"
  >
    <h2
      v-if="props.headerText"
      class="text-grey-900"
      :class="smallFont ? 'typo-h5' : 'typo-h2'"
    >
      {{ props.headerText }}
    </h2>
    <NuxtLink
      v-if="props.headerLink && props.headerLinkText"
      class="typo-body-xl text-brand-900 underline hover:text-brand-800"
      :class="smallFont ? 'typo-body' : 'typo-body-xl'"
      :to="props.headerLink"
      >{{ props.headerLinkText }}</NuxtLink
    >
  </div>
</template>

<script setup>
const props = defineProps({
  headerText: {
    type: String,
    default: null,
  },
  headerLink: {
    type: String,
    default: null,
  },
  headerLinkText: {
    type: String,
    default: null,
  },
  smallFont: {
    type: Boolean,
    default: false,
  },
});

function hasAnyContent() {
  return props.headerText || (props.headerLink && props.headerLinkText);
}
</script>
