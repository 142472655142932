<template>
  <div
    class="fixed z-10 flex h-[56px] w-full shrink-0 items-center justify-center border-b border-grey-200 bg-white"
  >
    <div class="flex w-full items-center justify-between px-4">
      <img
        :src="ogLogo"
        alt="Officeguru logo - letter O and G intertwining into an infinity symbol"
        width="46"
        height="24"
        class="max-w-[46px]"
      />
      <h2 class="typo-body text-center font-semibold text-grey-500">
        {{ $t("request_flow.offer_for_service", { service: service.name }) }}
      </h2>
      <BaseButton
        type="outline"
        color="black"
        size="small"
        class="border-grey-300"
        @click="confirmCancel"
        ><BaseIcon icon="close" :size="14" class="mr-1" />{{
          $t("request_flow.cancel")
        }}</BaseButton
      >
    </div>
    <RequestFlowComponentsSliderProgress
      :current="current"
      :amount-of-slides="amountOfSlides"
    />
  </div>
</template>

<script setup lang="ts">
import ogLogo from "~/assets/og-logo.svg?url";
const { t: $t } = useI18n();

interface Props {
  service: OgApi.Service;
  current: number;
  amountOfSlides: number;
}

withDefaults(defineProps<Props>(), {
  current: 1,
});

const emit = defineEmits(["close"]);

function confirmCancel() {
  const posthog = usePosthog();
  const confirmation = confirm($t("request_flow.sure_you_want_to_cancel"));

  posthog.capture("Request flow | Cancel pressed", {
    url: window.location.href,
  });

  if (confirmation) {
    posthog.capture("Request flow | Cancel press confirmed", {
      url: window.location.href,
    });

    emit("close");
  }
}
</script>
