<template>
  <template v-if="!isEmbedded">
    <div class="hidden items-center lg:flex">
      <MainMenuFull :items="items" />
    </div>
    <div class="flex items-center lg:hidden">
      <MainMenuCompact :items="items" />
    </div>
  </template>
  <template v-else>
    <div class="flex items-center">
      <MainMenuFull :items="items" />
    </div>
  </template>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const isEmbedded = useUIStore().isEmbedded;

defineProps({
  items: {
    type: Array,
    required: true,
  },
});
</script>
