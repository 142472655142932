<template>
  <div class="flex">
    <div
      v-if="hasBackgroundImage"
      class="flex shrink-0 rounded-full border border-grey-100 bg-grey-50 bg-cover bg-center"
      :class="calculatedSizeStyling"
      :style="`background-image: url('${backgroundImageUrl}')`"
      :title="image?.alt"
    />
    <div
      v-else
      class="flex shrink-0 items-center justify-center rounded-full border border-grey-200 bg-grey-50 text-semantic-info-600"
      :class="sizeStyling[size]"
      :style="fallbackStyle"
    >
      {{ initials(fallbackText) }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { initials } from "~/utils/initials";
import { useCmsImage } from "~/utils/use-cms-image";

interface Props {
  image: { url?: string; alt?: string } | null;
  fallbackText: string;
  // eslint-disable-next-line
  size?: keyof typeof sizeStyling;
  fallbackStyle?: { [key: string]: string };
}

const props = withDefaults(defineProps<Props>(), {
  size: "medium",
  fallbackStyle: undefined,
});

const calculatedSizeStyling = computed(() => {
  return sizeStyling[props.size as keyof typeof sizeStyling];
});

const sizeStyling = {
  xs: "w-8 h-8 typo-body",
  small: "w-10 h-10 typo-body",
  medium: "w-12 h-12 typo-body",
  large: "w-16 h-16 typo-body",
  "x-large": "w-19 h-19 typo-body",
  "xx-large": "w-22 h-22 typo-body",
};

const hasBackgroundImage = computed(() => !!props.image?.url);

const backgroundImageUrl = useCmsImage({
  src: props.image?.url || "",
  params: "width=88&height=88&fit=cover",
});
</script>
