<template>
  <div
    v-if="managedByCustomerName"
    class="-ml-4 flex w-[192px] border-t border-grey-200 bg-grey-100 px-4 py-1"
    :title="$t('officehub.managed_by_tooltip')"
  >
    <div class="flex w-full cursor-default gap-2 overflow-hidden">
      <div class="typo-caption flex grow flex-col overflow-hidden">
        <h4 class="text-grey-500">
          {{ $t("officehub.managed_by") }}
        </h4>
        <p class="truncate font-medium text-grey-600">
          {{ managedByCustomerName }}
        </p>
      </div>
      <div class="typo-caption flex content-center items-center text-grey-400">
        <BaseIcon icon="info-circle" :size="13" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOfficehub } from "~/utils/use-officehub";

const { managedByCustomerName } = useOfficehub();

interface Props {
  collapsed: boolean;
}

withDefaults(defineProps<Props>(), {
  collapsed: false,
});
</script>
