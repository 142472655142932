<template>
  <BaseButtonV2
    class="whitespace-nowrap"
    v-bind="$attrs"
    blank
    :href="href as string"
  >
    {{ $t("components.login") }}
  </BaseButtonV2>
</template>

<script setup lang="ts">
const $config = useRuntimeConfig();
const href = $config.public.customerAppUrl;
</script>
