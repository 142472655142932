<template>
  <div class="flex w-full flex-wrap gap-6 border-b border-b-grey-200 pb-1">
    <span
      v-for="(item, index) in visibleItems"
      :key="index"
      :aria-label="item.label"
      role="button"
      class="typo-body cursor-pointer text-grey-500 hover:text-brand-600"
      @click="emit('scroll-to', item.ref)"
      >{{ item.label }}</span
    >
  </div>
</template>

<script lang="ts" setup>
interface Anchor {
  ref: Ref;
  label: string;
  show: boolean;
  hidden?: boolean;
}

const emit = defineEmits(["scroll-to"]);

const props = defineProps({
  items: {
    type: Array as PropType<Anchor[]>,
    required: true,
  },
});

const visibleItems = computed(() => {
  return props.items.filter((item) => {
    if (typeof item.show !== "boolean") {
      throw new TypeError("AnchorList: item.show must be a boolean");
    }
    return item.show;
  });
});
</script>
