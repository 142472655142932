export function useBackgroundColor(props: any, fallback = "transparent") {
  return computed(() => {
    let color = props.content?.background_color;
    // possible to define the color w/o the hash in the CMS, so if we have a color but no hash, add it (only way
    // to define color in CMS is as hex, so this should be safe)
    if (color && !color.includes("#")) {
      color = `#${color}`;
    }

    return `background-color: ${color || fallback}`;
  });
}
