<template>
  <div
    class="typo-body inline-flex items-center justify-center rounded-md border px-1 leading-[1.26rem]"
    :class="colorClasses[color]"
  >
    <BaseIcon
      v-if="iconName"
      :icon="iconName"
      class="mr-1 shrink-0"
      :size="iconSize"
    /><span
      class="whitespace-nowrap"
      :title="truncatedText !== text ? text : ''"
      >{{ truncatedText }}</span
    >
  </div>
</template>

<script setup lang="ts">
const colorClasses = {
  blue: "bg-semantic-info-50 text-semantic-info-700 border-semantic-info-100",
  yellow:
    "bg-semantic-notice-50 text-semantic-notice-700 border-semantic-notice-100",
  grey: "bg-grey-50 border-grey-100 text-grey-500",
};

const props = withDefaults(
  defineProps<{
    iconName?: BaseIconName;
    iconSize?: number;
    text: string;
    color?: "blue" | "yellow" | "grey";
  }>(),
  {
    iconName: undefined,
    color: "blue",
    iconSize: 14,
  },
);
const truncatedText = computed(() => {
  return props.text.length > 30 ? `${props.text.slice(0, 30)}…` : props.text;
});
</script>
