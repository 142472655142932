<template>
  <BaseContent :style="backgroundColor">
    <div
      class="flex flex-col md:gap-20 lg:flex-row"
      :class="{
        'lg:flex-row-reverse': flipImagePosition,
        [verticalPadding]: !!verticalPadding,
      }"
    >
      <div class="mb-8 flex items-center justify-center md:mb-0 lg:w-3/6">
        <!-- done -->
        <CmsImage
          v-if="image"
          :src="image"
          :alt="imageAlt"
          :width="props.content.image.width"
          :height="props.content.image.height"
          :max-width="911"
          :class="{ [imageRounded]: !!imageRounded }"
          class="max-h-full max-w-full"
        />
      </div>
      <div class="flex flex-col justify-center lg:w-3/6">
        <h2 class="typo-h2">
          {{ content.headline }}
        </h2>
        <WysiwygRenderer
          v-if="hasText"
          :content="content.text"
          class="typo-body-xl mt-5 text-grey-700"
        />
        <BaseButton
          v-if="button"
          :href="button.link"
          class="mt-7"
          size="x-large"
        >
          {{ button.label }}
        </BaseButton>
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import BaseButton from "~/components/base-button.vue";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useImageRounded } from "~/components/cms-blocks/components/use-image-rounded";
import { useHasText } from "~/components/cms-blocks/components/use-has-text";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const flipImagePosition = computed(() => {
  return props.content.image_placement.value !== "left";
});

const image = computed(() => {
  return props.content.image.url;
});

const imageAlt = computed(() => {
  return props.content.image.alt;
});

const button = computed(() => {
  if (!props.content.button_link || !props.content.button_label) {
    return null;
  }
  return {
    label: props.content.button_label,
    link: props.content.button_link,
  };
});

const verticalPadding = useVerticalPadding(props);
const backgroundColor = useBackgroundColor(props);
const imageRounded = useImageRounded(props);
const hasText = useHasText(props);
</script>
