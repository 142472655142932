<template>
  <div itemscope itemtype="https://schema.org/BlogPosting">
    <BlogHero
      v-if="blogPost"
      :title="blogPost.title"
      :image="blogPost.image"
      :date="blogPost.date"
    />
    <BaseContent
      itemprop="articleBody"
      content-classes="flex lg:!flex-row gap-14"
    >
      <div class="flex flex-col">
        <template
          v-for="(content, index) in arrayifiedBlogPostContent"
          :key="content.id ?? index"
        >
          <WysiwygRenderer
            v-if="content.type === 'text'"
            :content="content.text"
            class="w-full"
          />
          <CmsBlocksFullWidthYoutube
            v-if="content.type === 'yt'"
            :wrap-in-base-content="false"
            :content="content"
            class="my-8"
          />
        </template>
      </div>
      <div class="lg:min-w-[353px] lg:max-w-[353px]">
        <div v-if="blogPost?.sidebar_recommended_blog">
          <p class="typo-h5 mb-6 font-semibold text-grey-900">
            {{ $t("blog.next_recommended_read") }}
          </p>
          <BlogPreview
            :post="blogPost.sidebar_recommended_blog"
            content-class="px-0"
            :excerpt-line-limit="3"
          />
        </div>

        <div v-if="cmsValues?.blog_sidebar_sendgrid_form_url">
          <CmsBlocksSendgridForm
            class="-mb-16 ml-[-8px]"
            :wrap-in-base-content="false"
            :content="{
              form_link: cmsValues.blog_sidebar_sendgrid_form_url,
              height: cmsValues.blog_sidebar_sendgrid_form_height ?? 600,
              vertical_padding: {},
            }"
          />
        </div>
      </div>
    </BaseContent>
    <CmsBlocksTextBox
      v-if="blogPost.hero_section?.[0]"
      :content="blogPost.hero_section?.[0]"
    />
    <BaseContent v-if="blogPost.footer_related_blogs.length">
      <p class="typo-h5 mb-4 font-semibold text-grey-900">
        {{ $t("blog.others_have_read") }}
      </p>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <BlogPreview
          v-for="post in blogPost.footer_related_blogs"
          :key="post.id"
          content-class="px-0"
          :post="post"
          :excerpt-line-limit="3"
        />
      </div>
    </BaseContent>
  </div>
</template>

<script lang="ts" setup>
import BlogHero from "~/pages/components/blog/blog-hero.vue";
import BlogPreview from "~/pages/components/blog/blog-preview.vue";
import { useCMSStore } from "~/store/CMSStore";
import { track } from "~/utils/tracking/tracking";

const $route = useRoute();
const { cmsValues } = useCMSStore();

const { data: blogData }: any = await useAsyncData(
  $route.name as string,
  () => {
    return $fetch($route.meta.apiUrl as string);
  },
);

const blogPost = computed<BlogPost>(() => blogData.value?.data);
const arrayifiedBlogPostContent = computed(() => {
  if (!blogPost.value) return [];

  return typeof blogPost.value.content === "string"
    ? [{ type: "text", text: blogPost.value.content }]
    : blogPost.value.content;
});

useSeo(blogPost?.value?.seo);

onMounted(() => {
  track({
    event: "view_blog",
    metadata: {
      blog_id: blogPost.value?.id,
      blog_name: blogPost.value?.title,
    },
    posthogEvent: {
      name: "View service",
      properties: {
        blog_id: blogPost.value?.id,
        blog_name: blogPost.value?.title,
      },
    },
  });
});
</script>
