import { useDevicePixelRatio } from "@vueuse/core";
import { preloadImage } from "~/utils/preload-image";

export function useCmsImage({
  src,
  params,
  // add as rel=preload to head if true
  preload = false,
}: {
  src: string;
  params: string;
  preload?: boolean;
}) {
  const dprQualityMap = {
    1: 70,
    2: 45,
    3: 25,
  };

  if (!src) {
    return computed(() => "");
  }

  if (src.toLowerCase().endsWith(".svg")) {
    return computed(() => src);
  }

  const searchParams = new URLSearchParams(params);

  if (!searchParams.has("width")) {
    // eslint-disable-next-line no-console
    console.error(
      "Params is mandatory, at least set a `width` (the maximum you ever need).",
    );
    return computed(() => "");
  }

  if (!searchParams.has("dpr")) {
    const { pixelRatio } = useDevicePixelRatio();
    let dpr = import.meta.server ? 2 : pixelRatio.value;

    if (dpr < 1) dpr = 1;
    if (dpr > 3) dpr = 3;

    searchParams.set("dpr", dpr.toString());
  }

  let dpr = Number(searchParams.get("dpr"));

  if (!isNaN(dpr)) {
    // fix values like 1.25 on some funny devices
    dpr = Math.round(dpr);
  }

  if (!(dpr in dprQualityMap)) {
    // eslint-disable-next-line no-console
    console.error(
      `Invalid dpr value ${dpr}. Supported is ${Object.keys(dprQualityMap).join(
        ", ",
      )}.`,
    );
    return computed(() => "");
  }
  searchParams.set(
    "quality",
    dprQualityMap[dpr as keyof typeof dprQualityMap].toString(),
  );

  const path = encodeURI(`${src}?${searchParams.toString()}`);

  if (preload) {
    preloadImage(path);
  }

  return computed(() => {
    return path;
  });
}
