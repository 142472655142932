<template>
  <div
    class="flex flex-wrap gap-x-4 gap-y-1 whitespace-nowrap text-caption text-grey-500"
  >
    <template v-for="(crumb, index) in crumbs" :key="index">
      <NuxtLink
        v-if="crumb.to"
        :to="crumb.to"
        :class="{ 'text-grey-400': isLast(index) }"
      >
        {{ crumb.title }}
      </NuxtLink>
      <span v-else :class="{ 'text-grey-400': isLast(index) }">
        {{ crumb.title }}
      </span>
      <BaseIcon
        v-if="!isLast(index)"
        icon="caret-down"
        :size="10"
        class="mr-[-9px] rotate-[270deg]"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from "#components";

interface Props {
  crumbs?: any[];
}

const props = withDefaults(defineProps<Props>(), {
  crumbs: () => [],
});

function isLast(index: number) {
  return props.crumbs.length - 1 === index;
}
</script>
