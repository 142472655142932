<template>
  <NuxtLink
    v-if="props.href"
    :to="props.href"
    :target="blank ? '_blank' : ''"
    tabindex="-1"
  >
    <button
      type="button"
      :class="`${commonClasses} ${colorStyling} ${sizeStyling} ${disabledStyling}`"
      :disabled="disabled"
    >
      <slot />
    </button>
  </NuxtLink>
  <button
    v-else
    type="button"
    :class="`${commonClasses} ${colorStyling} ${sizeStyling} ${disabledStyling}`"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
interface Props {
  type?: "primary" | "secondary" | "tertiary" | "link";
  tone?: "neutral" | "destructive";
  size?: "small" | "medium" | "large";
  href?: string | null;
  blank?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  href: null,
  blank: false,
  type: "primary",
  tone: "neutral",
  size: "large",
  disabled: false,
});

const sizeStyling = computed(() => {
  const sizeStyling = {
    small: `py-[0.25rem] px-4 text-body leading-[1.375rem] font-normal`,
    medium: `py-[0.5rem] px-5 text-body leading-[1.375rem] font-normal`,
    large: `py-[0.688rem] px-6 text-body-lg leading-6 font-normal`,
  };

  return sizeStyling[props.size as keyof typeof sizeStyling];
});

const colorStyling = computed(() => {
  const typeStyling = {
    primary: {
      neutral:
        "bg-grey-900 text-white border-transparent hover:bg-grey-800 active:bg-grey-900",
      destructive:
        "bg-semantic-negative-600 text-white border-transparent hover:bg-semantic-negative-500 active:bg-semantic-negative-600",
    },
    secondary: {
      neutral:
        "bg-transparent text-grey-900 border-grey-500 hover:border-grey-700 hover:bg-grey-50 active:bg-grey-100 active:border-grey-700",
      destructive:
        "bg-transparent text-semantic-negative-600 border-grey-500 hover:border-grey-700 hover:bg-grey-50 active:bg-grey-100 active:border-grey-700",
    },
    tertiary: {
      neutral:
        "bg-transparent text-grey-900 border-transparent underline hover:bg-grey-50 active:bg-grey-100",
      destructive:
        "bg-transparent text-semantic-negative-600 border-transparent underline hover:bg-grey-50 active:bg-grey-100",
    },
    link: {
      neutral:
        "bg-transparent text-grey-900 border-transparent underline hover:text-grey-700 active:text-grey-700 !px-0",
      destructive:
        "bg-transparent text-semantic-negative-600 border-transparent underline hover:text-semantic-negative-500 active:text-semantic-negative-500 !px-0",
    },
  };

  return typeStyling[props.type][props.tone];
});

const commonClasses =
  "flex items-center justify-center gap-1 rounded-md border border-solid font-medium focus:outline-1 focus:outline-grey-200 self-start";

const disabledStyling = computed(() => {
  return props.disabled ? "pointer-events-none opacity-30" : "";
});
</script>
