<template>
  <BaseContent :style="`${backgroundColor}`" class="bg-cover pt-20">
    <div class="grid grid-cols-2 gap-8">
      <div class="col-span-2 md:col-span-1">
        <div class="flex h-full flex-col justify-center">
          <div class="mb-6 md:hidden">
            <CmsImage
              v-if="service.thumbnail?.url"
              class="mb-6 h-[208px] w-auto rounded-lg object-cover object-center md:mb-0"
              :class="{ 'aspect-3/2': !isSvg(service.thumbnail.url) }"
              itemprop="image"
              :src="service.thumbnail.url"
              :alt="service.thumbnail.alt"
              :width="service.thumbnail.width"
              :height="service.thumbnail.height"
              :max-width="584"
              loading="eager"
              fetch-priority="high"
            />
          </div>
          <BreadcrumbBar
            :crumbs="breadcrumbs"
            class="order-first mb-10 md:order-none md:mb-0"
          />
          <h1
            class="typo-h3 mb-3.5 truncate font-bold text-grey-900"
            itemprop="name"
          >
            {{ service.title }}
          </h1>
          <p
            class="typo-body-lg text-grey-900"
            itemprop="disambiguatingDescription"
          >
            {{ service.short_description }}
          </p>
        </div>
      </div>
      <div class="hidden flex-col items-center justify-center md:flex">
        <CmsImage
          v-if="service.thumbnail?.url"
          class="h-[208px] w-auto rounded-lg object-cover object-center"
          :class="{ 'aspect-3/2': !isSvg(service.thumbnail.url) }"
          itemprop="image"
          :src="service.thumbnail.url"
          :alt="service.thumbnail.alt"
          :width="service.thumbnail.width"
          :height="service.thumbnail.height"
          :max-width="584"
          loading="eager"
          fetch-priority="high"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useCMSStore } from "~/store/CMSStore";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const { t } = useI18n();
const { cmsValues } = useCMSStore();
const props = defineProps<{
  service: Service;
}>();

const backgroundColor = useBackgroundColor({ content: props.service });

const breadcrumbs = computed(() => {
  return [
    {
      title: t("service_page.marketplace"),
      to: cmsValues?.link_marketplace_page || "/",
    },
    { title: t("all_services"), to: cmsValues?.link_all_services_page || "" },
    { title: props.service.title },
  ];
});

function isSvg(url: string) {
  if (!url) return;

  return url.includes(".svg");
}
</script>
