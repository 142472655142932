<template>
  <BaseContent v-if="fieldOne || fieldTwo" :style="backgroundColor">
    <div class="flex flex-wrap items-center md:flex-nowrap md:gap-x-12">
      <div v-if="fieldOne" class="w-full md:w-1/2">
        <CmsBlockRenderer
          :block="fieldOne"
          :page-data="props.content"
          :is-nested="isNested"
          :raw="raw"
        />
      </div>
      <div v-if="fieldTwo" class="w-full md:w-1/2">
        <CmsBlockRenderer
          :block="fieldTwo"
          :page-data="props.content"
          :is-nested="isNested"
          :raw="raw"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  raw: {
    type: Object,
    default: () => ({}),
  },
});

const isNested = computed(() => {
  return props.content.type === "2_column_components";
});

const fieldOne = computed(() => {
  return props.content.replicator_field?.[0];
});
const fieldTwo = computed(() => {
  return props.content.replicator_field?.[1];
});
const backgroundColor = useBackgroundColor(props);
</script>
