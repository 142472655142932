<template>
  <BaseContent
    :style="`${backgroundImageStyle} ${backgroundColor}`"
    class="bg-cover pt-16"
  >
    <div
      class="flex flex-col-reverse items-center justify-between lg:flex-row"
      :class="[
        verticalPadding,
        alternativeLayout ? 'flex-row-reverse' : 'flex-row',
      ]"
    >
      <div class="mt-8 lg:mt-0">
        <h1 v-if="headline" class="typo-h1 lg:mr-4">
          {{ headline }}
        </h1>
        <GlobalSearch class="mt-12" />
        <span
          v-if="searchSubtext"
          class="link-container typo-body-xl mt-3 block text-grey-700"
          v-html="searchSubtext"
        />
      </div>

      <!-- done -->
      <CmsImage
        :src="heroImage.url"
        :alt="heroImage.alt"
        :width="heroImage.width"
        :height="heroImage.height"
        :max-width="864"
        class="lg:inline lg:max-w-[50%]"
        loading="eager"
        fetch-priority="high"
      />
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useCmsImage } from "~/utils/use-cms-image";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const searchSubtext = computed(() => {
  return props.content?.search_subtext;
});

const headline = computed(() => {
  return props.content.header_text;
});

const heroImage = computed(() => {
  return {
    url: props.content.hero_image?.url,
    alt: props.content.hero_image?.alt,
    width: props.content.hero_image?.width,
    height: props.content.hero_image?.height,
  };
});

const backgroundImageStyle = computed(() => {
  if (!props.content?.background_image?.url) return "";

  return `background-image: url('${
    useCmsImage({
      src: (props.content?.background_image?.url || "").replace(
        "http://",
        "https://",
      ),
      params: "width=1280",
      preload: true,
    }).value
  }')`;
});

const backgroundColor = useBackgroundColor(props);

const alternativeLayout = computed(() => {
  return props.content.alternative_layout;
});

const verticalPadding = useVerticalPadding(props);
</script>

<style scoped>
.link-container :deep(a) {
  @apply underline;
}
</style>
