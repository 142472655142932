<template>
  <div class="flex flex-col items-center">
    <div
      class="w-full rounded-lg bg-white p-8 pb-5 shadow-alternative-soft-glow"
    >
      <form v-if="!success">
        <h2 class="typo-h3 mb-2">
          {{ $t("vendor_signup_form.get_new_customers_today") }}
        </h2>
        <span class="typo-body-xl mb-5 block text-grey-700">{{
          $t("vendor_signup_form.apply_for_free")
        }}</span>
        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('vendor_signup_form.label.company')"
          field-name="partner.name"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.partner.name"
            :placeholder="$t('vendor_signup_form.placeholder.company')"
            error-name-space="vendor_signup_form"
            field-name="partner.name"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('vendor_signup_form.label.name')"
          field-name="user.name"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.user.name"
            :placeholder="$t('vendor_signup_form.placeholder.name')"
            error-name-space="vendor_signup_form"
            field-name="user.name"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('vendor_signup_form.label.phone')"
          field-name="user.phone"
          :errors="errors"
        >
          <FormsComponentsInputText
            v-model:value="form.user.phone"
            :placeholder="$t('vendor_signup_form.placeholder.phone')"
            error-name-space="vendor_signup_form"
            field-name="user.phone"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('vendor_signup_form.label.email')"
          field-name="user.email"
          :errors="errors"
          required
        >
          <FormsComponentsInputText
            v-model:value="form.user.email"
            :placeholder="$t('vendor_signup_form.placeholder.email')"
            error-name-space="vendor_signup_form"
            field-name="user.email"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          class="mb-3"
          :label="$t('vendor_signup_form.label.what_services')"
          field-name="partner.description"
          :errors="errors"
        >
          <FormsComponentsInputTextarea
            v-model:value="form.partner.description"
            :placeholder="$t('vendor_signup_form.placeholder.what_services')"
            error-name-space="vendor_signup_form"
            field-name="partner.description"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <FormsComponentsFieldLabel
          :label="
            $t('vendor_signup_form.label.marketing_content_and_newsletter')
          "
          field-name="user.gdpr"
          :errors="errors"
          for-checkbox
        >
          <FormsComponentsInputCheckbox
            v-model:value="form.user.gdpr"
            error-name-space="vendor_signup_form"
            field-name="user.gdpr"
            :errors="errors"
          />
        </FormsComponentsFieldLabel>

        <BaseButton :disabled="loading" class="mb-4 mt-7 w-full" @click="submit"
          >{{ $t("vendor_signup_form.button.submit") }}
        </BaseButton>

        <span
          v-if="genericError"
          class="typo-caption mt-3 flex max-w-[60ch] items-baseline text-semantic-negative-700"
        >
          <BaseIcon class="mr-1" icon="close" :size="12" color="red" />
          {{ $t("vendor_signup_form.generic_error") }}
        </span>

        <div class="typo-body mt-6 text-center">
          <span class="text-grey-500"
            >{{ $t("vendor_signup_form.contact_us") }}
            <a href="tel:+45 4399 1529" class="text-grey-700"
              >+45 4399 1529</a
            ></span
          >
        </div>
      </form>
      <div v-else>
        <FormsVendorSignupFormSuccess />
      </div>
    </div>
    <FormsComponentsRecaptchaInfo class="mt-6 px-8" />
  </div>
</template>

<script setup lang="ts">
import { submitApiForm } from "~/utils/form";

const $posthog = usePosthog();

const form = reactive({
  partner: {
    name: "",
    description: "",
  },
  user: {
    name: "",
    phone: "",
    email: "",
    gdpr: false,
  },
});

const genericError = ref(false);
const errors = ref({});
const loading = ref(false);
const success = ref(false);

async function submit() {
  loading.value = true;

  try {
    await submitApiForm("public/partners", {
      type: "minimum",
      ...form,
      distinct_id: $posthog.id,
    });

    // identify with posthog so we get the events tracked for the proper user
    $posthog.identify(form.user);

    setStatus(true, false, {});
  } catch (e: any) {
    if (e?.data?.errors) {
      setStatus(false, false, e.data.errors);
    } else {
      setStatus(false, true, {});
      useBugsnag().notify(e);
    }
  } finally {
    loading.value = false;
  }
}

function setStatus(
  successValue: boolean,
  genericErrorValue: boolean,
  errorsValue: any,
) {
  success.value = successValue;
  genericError.value = genericErrorValue;
  errors.value = errorsValue;
}
</script>
