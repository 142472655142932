<template>
  <div class="animate-redraw pb-20">
    <div v-if="!triedSubmit" class="rounded-lg border border-grey-200 p-6">
      <div class="mb-8">
        <h2 class="typo-h5 mb-2 text-semantic-info-700">
          {{
            $t(
              "request_flow.confirm_your_details_before_we_send",
              props.payload.partner_ids?.length || 1,
            )
          }}
        </h2>
      </div>
      <div class="grid gap-6">
        <div
          v-for="item in summaryItems"
          :key="item.step"
          class="flex flex-col"
        >
          <div class="typo-body flex justify-between">
            <h3 class="mb-2">{{ item.question }}</h3>
            <span
              role="button"
              class="ml-4 cursor-pointer whitespace-nowrap"
              @click="onEdit(item.step, item.id)"
            >
              <BaseIcon icon="edit" :size="14" class="-mb-px mr-2" />
              <span class="typo-body font-medium">{{
                $t("request_flow.edit")
              }}</span>
            </span>
          </div>
          <div class="typo-body max-w-[90%] font-medium">
            <template v-if="!item.renderComponent">
              {{ item.answer }}
            </template>
            <template v-else>
              <component
                :is="item.renderComponent"
                :answer="item.answer"
                @add-more="onEdit(item.step, item.id)"
              />
            </template>
          </div>
        </div>
        <div class="mt-2 flex flex-col">
          <div class="flex justify-center">
            <RequestFlowComponentsSlidesComponentsButtonGroup
              v-if="!triedSubmit"
              :disable-next="submitting"
              :next-text="$t('request_flow.confirm_and_send')"
              custom-next-icon="checkmark_circle"
              @back="emit('back')"
              @next="submit"
            />
          </div>
          <div class="mt-4 flex justify-center">
            <span class="typo-body text-grey-500">{{
              $t("request_flow.billed_after_completion")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="errors">
      <div class="flex flex-col justify-center">
        <img :src="errorImage" alt="" class="w-36" loading="lazy" />
        <h3 class="typo-h5 mt-6 font-semibold text-grey-600">
          {{ $t("request_flow.sorry_something_went_wrong") }}
        </h3>
        <p class="typo-body mt-2 text-grey-600">
          {{ $t("request_flow.issue_on_our_end_working_on_it") }}
        </p>
        <div class="typo-body mt-4 text-grey-600">
          <span
            class="cursor-pointer text-brand-600"
            :class="{
              'pointer-events-none cursor-wait opacity-50': submitting,
            }"
            @click="submit"
            ><BaseIcon
              v-if="submitting"
              icon="loading"
              class="mr-1 animate-spin"
              :size="11"
            /><template v-if="!submitting">{{
              $t("request_flow.try_again")
            }}</template
            ><template v-else>{{
              $t("request_flow.trying_again")
            }}</template></span
          >
          {{ $t("request_flow.or") }}
          <a href="tel:+4543991529" class="text-brand-600"
            >{{ $t("request_flow.call_us_for_help") }} +45 4399 1529</a
          >
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center">
      <LottieAnimation :file="successAnimation" width="200px" />
      <p
        class="typo-h3 mt-12 block animate-redraw bg-gradient-to-b from-[#F08F30] to-[#B60BD4] bg-clip-text text-center text-transparent"
      >
        {{ $t("request_flow.sending_request") }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { track } from "~/utils/tracking/tracking";
import { useUserStore } from "~/store/UserStore";
import {
  RequestFlowComponentsSlidesComponentsSummaryVendors,
  RequestFlowComponentsSlidesComponentsSummaryComposite,
  RequestFlowComponentsSlidesComponentsSummaryWeekdays,
  RequestFlowComponentsSlidesComponentsSummaryFiles,
} from "#components";
import { useAuthStore } from "~/store/AuthStore";
import { useLeaveWarning } from "~/utils/use-leave-warning";
import { useUIStore } from "~/store/UIStore";
import successAnimation from "~/assets/lottie/submitting_task.json";
import errorImage from "~/assets/frickin_puter.svg?url";
const { t: $t } = useI18n();

const props = defineProps<{
  payload: OgApi.TaskPayload;
  service: OgApi.Service;
}>();

interface SummaryItem {
  question: string;
  answer: string | [];
  renderComponent?: any;
  step: number;
  id?: string;
}

const emit = defineEmits(["back", "go-to-step"]);

const submitting = ref(false);
const triedSubmit = ref(false);
const errors: Ref<any> = ref(false);

const { locations } = storeToRefs(useUserStore());
const selectedLocation = computed(() => {
  return locations.value.find((loc) => loc.id === props.payload.location_id);
});

// when we tried to submit, we don't want the header as the animation and error stuff
// is all inside a "full screen" screen
watchEffect(() => {
  const { hideRequestFlowHeader, showRequestFlowHeader } = useUIStore();
  submitting.value ? hideRequestFlowHeader() : showRequestFlowHeader();
});

const { data: vendorData }: any = await useLazyAsyncData(`vendors-all`, () => {
  return $fetch(
    // no need to use the auth token here, since the public endpoint serves the same
    `${useRuntimeConfig().public.apiUrl}public/services/${
      props.payload.service_id
    }/partners`,
  );
});

const vendors = computed<[OgApi.Vendor]>(() => vendorData.value?.data);

const summaryItems = computed(() => {
  const selectedVendors = {
    question: $t("request_flow.what_vendors_would_you_like_offers_from"),
    answer: props.payload.partner_ids.map(
      (id) => vendors.value.find((v) => v.id === id)?.name,
    ),
    renderComponent: RequestFlowComponentsSlidesComponentsSummaryVendors,
    step: props.payload.answers.length + 1,
    id: "vendor-selection",
  } as SummaryItem;

  const location = {
    question: $t("request_flow.which_location_to_deliver_to"),
    answer: selectedLocation.value?.address,
    step: props.payload.answers.length + 1,
  } as SummaryItem;

  return [
    selectedVendors,
    location,
    ...props.payload.answers.map((answer, index) => {
      return {
        question: answer.question,
        answer: getPreformattedQuestionAnswer(answer.type, answer.answer),
        step: index + 1,
        renderComponent: getQuestionSummaryRenderComponent(answer.type),
      } as SummaryItem;
    }),
  ];
});

function getQuestionSummaryRenderComponent(type: string) {
  const componentMap = {
    composite: RequestFlowComponentsSlidesComponentsSummaryComposite,
    week_selector: RequestFlowComponentsSlidesComponentsSummaryWeekdays,
    file: RequestFlowComponentsSlidesComponentsSummaryFiles,
  };

  return componentMap[type as keyof typeof componentMap] || undefined;
}

function getPreformattedQuestionAnswer(type: string, answer: any) {
  const typeToComponent = {
    composite: answer,
    week_selector: answer,
    file: answer,
  };

  const mappedAnswer = typeToComponent[type as keyof typeof typeToComponent];
  if (mappedAnswer !== undefined) {
    return mappedAnswer;
  }

  if (Array.isArray(answer)) {
    return answer.join(", ");
  } else {
    return answer || "-";
  }
}

async function submit() {
  const GUARANTEED_ANIMATION_SHOW_TIME = 2000;

  errors.value = null;
  submitting.value = true;
  triedSubmit.value = true; // never reset this

  // filter questions in payload that are empty
  const answersWithoutEmpty = props.payload.answers.filter((answer) => {
    return (
      answer.answer !== null &&
      answer.answer !== undefined &&
      answer.answer !== ""
    );
  });

  try {
    const requestStart = new Date().getTime();
    const { data }: any = await globalThis.$fetch(
      `${useRuntimeConfig().public.apiUrl}customer/tasks`,
      {
        method: "POST",
        body: JSON.stringify({
          ...props.payload,
          answers: answersWithoutEmpty,
        }),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${useAuthStore().token}`,
        },
      },
    );
    const requestEnd = new Date().getTime();

    track({
      event: "request_flow_completed",
      metadata: {
        order_id: data.id,
        service_id: props.service.id,
        service_name: props.service.name,
        type: "service",
        products: props.payload.partner_ids.map((id) => ({
          product_id: id,
          name: vendors.value.find((v) => v.id === id)?.name || "",
        })),
      },
      posthogEvent: {
        name: "Request flow | Completed",
        properties: {
          url: window.location.href,
        },
      },
    });

    // show the nice animation a bit
    setTimeout(
      () => {
        useLeaveWarning().deactivate();
        window.location.href = `${
          useRuntimeConfig().public.customerAppUrl
        }tasks/${data.id}?origin=request_flow`;
      },
      GUARANTEED_ANIMATION_SHOW_TIME +
        Math.max(
          0,
          GUARANTEED_ANIMATION_SHOW_TIME - (requestEnd - requestStart),
        ),
    ); // always wait 2 seconds
  } catch (e: any) {
    errors.value = e;
    submitting.value = false;

    useBugsnag().notify(new Error(`Request flow submit failed`), (event) => {
      event.addMetadata("additional", {
        error: e,
        payload: props.payload,
        answers: answersWithoutEmpty,
      });
    });
  }
}

function onEdit(desiredStep: number, id?: string) {
  if (id === "vendor-selection") {
    const posthog = usePosthog();
    posthog.capture("Request flow | Edit vendor selection", {
      url: window.location.href,
    });
  }
  emit("go-to-step", desiredStep);
}
</script>
