<template>
  <p class="typo-body mb-6 font-semibold text-grey-500">
    {{
      $t("vendor_page.latest_count_reviews", { count: reviewsShowing.length })
    }}
  </p>

  <!-- List of reviews -->
  <div class="flex flex-col gap-8">
    <div v-for="(review, index) in reviewsShowing" :key="index">
      <div
        class="flex flex-col"
        itemprop="review"
        itemscope
        itemtype="https://schema.org/Review"
      >
        <div class="mb-4 flex justify-between">
          <div class="flex">
            <div
              class="typo-body mr-2 flex h-[48px] w-[48px] items-center justify-center rounded-full border border-grey-200 bg-grey-50 font-medium text-semantic-info-800"
            >
              {{ review.user.initials }}
            </div>
            <div>
              <p
                class="typo-body font-semibold text-grey-900"
                itemprop="author"
              >
                {{ review.user.name }}
              </p>
              <p
                v-if="serviceMap[review.service_id]"
                class="typo-body text-grey-500"
                itemprop="name"
              >
                {{ $t("vendor_page.rated") }}
                <NuxtLink
                  :to="serviceDetailPath(serviceMap[review.service_id].slug)"
                  class="typo-body font-medium text-brand-600"
                >
                  {{ serviceMap[review.service_id].name }}
                </NuxtLink>
              </p>
            </div>
          </div>
          <p class="typo-body capitalize text-grey-500">
            {{ format(parseISO(review.created_at), "MMMM yyyy") }}
          </p>
        </div>
        <div
          class="mb-4 flex gap-4"
          itemprop="reviewRating"
          itemscope
          itemtype="https://schema.org/Rating"
        >
          <RatingStars :rating="review.rating" class="gap-1" />
          <meta itemprop="worstRating" content="1" />
          <meta
            v-if="review.rating"
            itemprop="ratingValue"
            :content="review.rating.toString()"
          />
          <meta itemprop="bestRating" content="5" />
          <div
            v-if="review.hire_again"
            class="typo-body flex h-[20px] items-center gap-1 rounded-full border border-semantic-positive-100 bg-semantic-positive-50 px-2 leading-[20px] text-semantic-positive-600"
          >
            <BaseIcon :size="12" icon="thumbs-up" />
            {{ $t("vendor_page.would_hire_again") }}
          </div>
        </div>
        <div
          v-if="review.description"
          class="typo-body text-grey-700"
          itemprop="reviewBody"
        >
          {{ review.description }}
        </div>
      </div>
      <div v-if="review.reply" class="mt-2 rounded-lg bg-grey-50 p-4">
        <div class="mb-2 flex items-center text-grey-500">
          <div class="flex w-8 items-center">
            <BaseIcon icon="reply" :size="14" />
          </div>
          <div class="typo-body flex-1 text-grey-500">
            {{ $t("vendor_page.answer_from_name", { name: vendor.name }) }}
          </div>
          <div class="typo-body capitalize text-grey-500">
            {{ format(parseISO(review.created_at), "MMMM yyyy") }}
          </div>
        </div>
        <ExpandableDescription
          v-if="review.reply"
          :description="review.reply"
          class="!border-none !pb-0 pl-8"
          :cut-off="24"
          plain-layout
          button-color="black-900"
        />
      </div>
    </div>

    <!-- Load more button -->
    <BaseButton
      v-if="reviewLimit < sortedReviews.length"
      type="clear"
      class="self-center"
      @click="loadMore(10)"
    >
      {{ $t("vendor_page.load_count_more", { count: loadMoreCount() }) }}
    </BaseButton>

    <!-- No matches -->
    <div
      v-if="
        reviewsShowing.length === 0 && (serviceFilter || ratingFilter !== null)
      "
      class="typo-body flex items-center justify-center py-4 text-center text-grey-500"
    >
      {{ $t("vendor_page.no_reviews_match_your_filters") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format, parseISO } from "date-fns";
import ExpandableDescription from "~/pages/components/expandable-description.vue";
import serviceDetailPath from "~/utils/service-detail-path";

interface ServiceMap {
  [key: string]: OgApi.Service;
}

interface Props {
  serviceFilter: string | null;
  ratingFilter: number | null;
  vendor: OgApi.VendorFull;
  serviceMap: ServiceMap;
}

const props = defineProps<Props>();

const reviewLimit = ref(3);

const sortedReviews = computed(() => {
  const filteredReviews = [...props.vendor.reviews]
    .filter((review) => {
      if (props.serviceFilter === null) {
        return true;
      }
      return review.service_id === props.serviceFilter;
    })
    .filter((review) => {
      if (props.ratingFilter === null) {
        return true;
      }
      return review.rating === props.ratingFilter;
    });

  return filteredReviews.sort((a, b) => {
    return parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime();
  });
});

const reviewsShowing = computed(() => {
  return sortedReviews.value.slice(0, reviewLimit.value);
});

function loadMoreCount() {
  return Math.min(10, sortedReviews.value.length - reviewLimit.value);
}

function loadMore(amount: number) {
  reviewLimit.value += Math.min(
    amount,
    sortedReviews.value.length - reviewLimit.value,
  );
}
</script>
