<template>
  <BaseContent :class="verticalPadding">
    <div
      class="flex max-w-[1216px] flex-col gap-8 rounded-lg px-7 py-6 md:px-9 md:py-8 lg:flex-row-reverse"
      :style="backgroundColor"
    >
      <div
        v-if="props.content?.image?.url"
        class="flex lg:items-center lg:justify-center"
      >
        <CmsImage
          :src="props.content?.image?.url"
          :width="props.content?.image?.width"
          :height="props.content?.image?.height"
          :max-width="180"
          class="max-w-[180px]"
        />
      </div>
      <div class="flex w-full flex-col">
        <h2 v-if="content.headline" class="typo-h2">
          {{ content.headline }}
        </h2>

        <WysiwygRenderer
          v-if="content.text"
          :content="content.text"
          class="mt-5"
        />

        <NuxtLink
          v-if="content.button_label && pathResolves(content.button_link)"
          :to="content.button_link"
          class="mt-7 inline-block"
          :target="buttonLinkTarget"
        >
          <BaseButtonV2>
            {{ content.button_label }}
          </BaseButtonV2>
        </NuxtLink>
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const verticalPadding = useVerticalPadding(props);
const backgroundColor = useBackgroundColor(props);

function pathResolves(path: string) {
  if (!path) return false;

  if (path.startsWith("http")) return true;

  const exists = useRouter().resolve(path).matched.length > 0;

  if (!exists) {
    useBugsnag().notify(
      new Error(
        `Button target ${path} does not exist in routes. Hiding button.`,
      ),
    );
  }

  return exists;
}
const buttonLinkTarget = props.content.button_link?.startsWith("http")
  ? "_blank _noopener _noreferrer"
  : "_self";
</script>
