<template>
  <div class="flex gap-8">
    <div class="flex flex-col">
      <h3 class="typo-h5 mb-1 font-semibold text-grey-900">
        {{ $t("vendor_page.verification_details") }}
      </h3>
      <p class="typo-body mb-4 text-grey-500">
        {{ $t("vendor_page.verification_description") }}
      </p>
      <div v-if="approvedDate" class="flex flex-col">
        <p class="typo-caption mb-1 font-semibold text-semantic-positive-600">
          <BaseIcon icon="verified-checkmark" :size="10" />
          {{ $t("vendor_page.verified") }}
        </p>
        <p class="typo-body font-medium text-grey-900">
          {{
            $t("vendor_page.on_date", {
              date: approvedDate,
            })
          }}
        </p>
      </div>
    </div>
    <div class="flex flex-col">
      <h3 class="typo-h5 mb-1 font-semibold text-grey-900">
        {{ $t("vendor_page.terms_of_payment") }}
      </h3>
      <p class="typo-body mb-4 text-grey-500">
        {{ $t("vendor_page.terms_of_payment_description") }}
      </p>
      <NuxtLink
        :to="paymentTermsLink"
        target="_blank"
        class="typo-body flex items-center gap-2 font-medium text-grey-900"
      >
        {{ $t("vendor_page.view_terms_of_payment") }}
        <BaseIcon icon="link-external" :size="14" />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { parseISO, format, isValid } from "date-fns";

const props = defineProps({
  vendor: {
    type: Object,
    required: true,
  },
  paymentTermsLink: {
    type: String,
    required: true,
  },
});

const approvedDate = computed(() => {
  if (!isValid(parseISO(props?.vendor?.approved_at))) return null;
  return format(parseISO(props.vendor.approved_at), "d. MMM yyyy");
});
</script>
