<template>
  <BaseBadge
    :text="label as string"
    :icon-name="displayIcon as BaseIconName"
    :icon-size="16"
  />
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ label?: string }>(), {
  label: "",
});

const labelIconMap: { [key: string]: BaseIconName } = {
  "top-vendor": "trophy",
  "in-high-demand": "star-circle",
};

const displayIcon: ComputedRef<BaseIconName | boolean> = computed(() => {
  if (!props.label) return false;
  return labelIconMap[props.label] ? labelIconMap[props.label] : false;
});
</script>
