<template>
  <div>
    <div class="typo-caption mb-4 flex gap-4 text-grey-500">
      <NuxtLink
        class="min-w-[16px] truncate"
        :to="
          cmsValues?.link_marketplace_page
            ? cmsValues.link_marketplace_page
            : '/'
        "
        >{{ $t("vendor_page.marketplace") }}</NuxtLink
      >
      <span
        ><BaseIcon icon="caret-down" :size="10" class="rotate-[270deg]"
      /></span>
      <span>{{ $t("vendor_page.vendors") }}</span>
      <span
        ><BaseIcon icon="caret-down" :size="10" class="rotate-[270deg]"
      /></span>
      <span>{{ vendor.name }}</span>
    </div>
    <div class="flex items-start gap-4">
      <div
        class="typo-h5 flex h-[64px] w-[64px] min-w-[64px] items-center justify-center rounded-full border-grey-200 bg-grey-50 bg-cover bg-center text-semantic-info-800"
        :class="{ border: !logo }"
        :style="logo"
      >
        <span v-if="!logo">{{ initials(vendor.name) }}</span>
        <meta
          v-if="logo"
          itemprop="logo"
          :content="
            (typeof vendor.images?.logo === 'object'
              ? vendor.images.logo?.url
              : vendor.images?.logo) || ''
          "
        />
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-3">
          <h1 class="typo-h4 font-bold" itemprop="name">
            {{ vendor.name }}
          </h1>
          <p class="typo-caption font-semibold text-semantic-positive-600">
            <BaseIcon icon="verified-checkmark" :size="10" />
            {{ $t("vendor_page.verified") }}
          </p>
        </div>
        <div class="flex items-center gap-4">
          <span
            v-if="vendor.badges?.['new'] && !vendor.rating.count"
            class="typo-body text-grey-700"
          >
            {{ $t("request_flow.new_on_officeguru") }}
          </span>
          <div
            v-else
            class="flex items-center gap-1"
            itemprop="aggregateRating"
            itemscope
            itemtype="https://schema.org/AggregateRating"
          >
            <span class="typo-body text-grey-900" itemprop="ratingValue">{{
              toOneDecimal(vendor.rating.score)
            }}</span>
            <BaseIcon class="md:hidden" icon="star-full" :size="16" />
            <RatingStars
              class="hidden gap-1 md:flex"
              :rating="vendor.rating.score"
            />
            <span class="typo-body text-grey-500"
              >({{ vendor.rating.count }})</span
            >
            <meta
              itemprop="reviewCount"
              :content="vendor.rating.count?.toString() || '0'"
            />
            <meta itemprop="bestRating" content="5" />
          </div>
          <span v-if="deliversToYou" class="text-grey-200">•</span>
          <div
            v-if="deliversToYou"
            class="typo-body flex items-center gap-2 text-grey-700"
          >
            <BaseIcon icon="location" :size="14" />
            <p>{{ $t("vendor_page.delivers_to_you") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUIStore } from "~/store/UIStore";
import { useCMSStore } from "~/store/CMSStore";
import { toOneDecimal } from "~/utils/rounding";
import { useCmsImage } from "~/utils/use-cms-image";
import { initials } from "~/utils/initials";

const { cmsValues } = useCMSStore();
const uiStore = useUIStore();
interface Props {
  vendor: OgApi.VendorFull;
}

const props = defineProps<Props>();

const logo = computed(() => {
  return props.vendor.images?.logo
    ? `background-image: url("${
        useCmsImage({
          src:
            (typeof props.vendor.images?.logo === "object"
              ? props.vendor.images.logo.url
              : props.vendor.images?.logo) || "",
          params: "width=64&height=64",
        }).value
      }");`
    : "";
});

const deliversToYou = computed(() => {
  const zip = uiStore.zipCode;
  const zipRanges = props.vendor.zip_ranges;
  if (!zip || !zipRanges?.length) {
    return false;
  }
  return zipRanges.some((range: { from: string; to: string }) => {
    return zip >= range.from && zip <= range.to;
  });
});
</script>
