<template>
  <div>
    <a
      v-if="item.to !== 'services'"
      :href="`${customerAppUrl}${item.to}`"
      class="flex items-center rounded-md border border-transparent px-1.5 py-0.5 transition-colors duration-200 ease-in-out hover:bg-grey-100"
      :class="{
        'pointer-events-none text-white': item.to === 'services',
      }"
    >
      <BaseIcon
        :icon="item.iconName"
        :size="16"
        :style="{ 'margin-bottom': item.marginBottom }"
        class="text-grey-500"
      />
      <span
        class="ml-3 flex h-[24px] items-center text-body text-grey-700"
        :class="!labels ? 'hidden' : 'inline'"
        >{{ item.label }}</span
      >
    </a>
    <NuxtLink
      v-else
      :to="cmsValues?.link_marketplace_page"
      class="flex items-center rounded-md bg-grey-200 px-1.5 py-0.5 transition-colors duration-200 ease-in-out"
      @click="hideMobileCustomerNav"
    >
      <BaseIcon
        :icon="item.iconName"
        :size="16"
        :style="{ 'margin-bottom': item.marginBottom }"
        class="text-grey-500"
      />
      <span
        class="ml-3 flex h-[24px] items-center text-body text-grey-700"
        :class="!labels ? 'hidden' : 'inline'"
        >{{ item.label }}</span
      >
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { useCMSStore } from "~/store/CMSStore";
const { hideMobileCustomerNav } = useUIStore();
const { cmsValues } = useCMSStore();
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;

interface Props {
  item: CustomerApp.NavigationItem;
  labels?: Boolean;
}

defineProps<Props>();
</script>
